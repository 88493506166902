import React, { useRef, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ChangeUserPassword from './changeUserPassword';
import Modal from 'react-bootstrap/Modal';
import Pricing from './pricing';
import AdditionalCredits from './additionalcredits';

function ProfileDropdown({ email, onLogout, closeDropdown, loggedInUserId, loggedInUserPlan, loggedInUserName, loggedInUserProfile }) {
    const dropdownRef = useRef(null);
    const changePasswordRef = useRef(null);
    const [credits, setCredits] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [mailVerificationKey, setMailVerificationKey] = useState('');
    const [isMailVerified, setIsMailVerified] = useState('')
    const [loginType, setLoginType] = useState();
    const [showUserPasswordModal, setShowUserPasswordModal] = useState(false)
    const handleUserPasswordModal = () => setShowUserPasswordModal(false);
    const [confirmUserPasswordModal, setConfirmUserPasswordModal] = useState(false)
    const [oldPassword, setOldPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [pricingdModal, setPricingModal] = useState(false)
    const [additionalCreditsdModal, setAdditionalCreditsModal] = useState(false)
    const [userPlan, setUserPlan] = useState({planId:loggedInUserPlan.id, planName:loggedInUserPlan.plan})
    const [passwordVisible, setPasswordVisible] = useState(false);

    // Close dropdown when clicking outside of it
    const handleClickOutside = (event) => {
        const profileIcon = document.getElementById('profile-icon');
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            (!profileIcon || !profileIcon.contains(event.target)) &&
            !showUserPasswordModal && // Only close dropdown if showUserPasswordModal is false
            !confirmUserPasswordModal &&
            !pricingdModal &&
            !additionalCreditsdModal
        ) {
            closeDropdown();
        }
    };
    // Attach click event listener to the document body
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [closeDropdown, showUserPasswordModal, confirmUserPasswordModal, pricingdModal, additionalCreditsdModal]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userresponse = await axios.get(`/user/${loggedInUserId}`);
                setMailVerificationKey(userresponse.data[0].mailverificationkey);
                setIsMailVerified(userresponse.data[0].ismailverified.data[0]);
                setLoginType(userresponse.data[0].logintype)
                setUserPlan({planId:userresponse.data[0].planid, planName:userresponse.data[0].plan})
                const response = await axios.get(`/usercredits/${loggedInUserId}`);
                setCredits(response.data.noofcredits);
               
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const handleSendMail = async () => {
        try {
            setSuccessMessage('Please check your registered mail');
            const response = await axios.post(`/sendMail`, { loggedInUserId, email, mailVerificationKey });

        } catch (err) {

        }
    }

    const handleChangePassword = async () => {
        setConfirmUserPasswordModal(true)
    }

    useEffect(() => {
        // Clear the success message after a brief delay
        const timeoutId = setTimeout(() => {
            setSuccessMessage('');
        }, 3000);

        return () => {
            // Clear the timeout when the component unmounts
            clearTimeout(timeoutId);
        };
    }, [successMessage]);

    function handlePasswordChange(event) {
        const newPassword = event.target.value;
        setOldPassword(newPassword);
    };

    async function handleConfirmPassword() {
        try {

            const response = await axios.post(`/confirmpassword`, { email, oldPassword });
            if (response.data.success) {
                setConfirmUserPasswordModal(false)
                setShowUserPasswordModal(true)
            }

        } catch (err) {
            setErrorMessage("Password do not match")
        }

    };

    const handlePricing = async () => {
        setPricingModal(true)
    }

    const handlePricingModal = () => setPricingModal(false);
    const showadditionalCreditsdModal = async () => {
        const accountData = await axios.get(`/account/${loggedInUserId}`);

        if (accountData && Array.isArray(accountData.data) && accountData.data.length > 0) {
            const userAccount = accountData.data[0];
            if (userAccount.address !== null) {
                setAdditionalCreditsModal(true);
            } else {
                window.location.href = `/accountdetails?&type=additional`;
            }
        } else {
            window.location.href = `/accountdetails?&type=additional`;
        }
    }
    const hideadditionalCreditsdModal = () => setAdditionalCreditsModal(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
      };
    return (
        <div>
            <div ref={dropdownRef} className='position-fixed me-1 end-0 p-3 text-dark bg-white border border-gray' style={{ width: '240px', zIndex: '1', top: '58px', borderRadius: '3%', boxShadow: "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px", fontSize: "14px" }}>
                <div className='d-flex flex-column'>
                    <div className='mx-auto mb-2'>{email}</div>
                    <div className='mx-auto my-2'>
                        {loggedInUserProfile ? (
                            <img src={loggedInUserProfile} alt={loggedInUserName.substring(0, 1).toUpperCase()} className="profile-img" style={{ height: 60, width: 60, objectFit: 'fill', borderRadius: '50%' }} />
                        ) : (
                            <Button className='btn-md rounded-circle d-flex align-item-center justify-content-center' style={{ height: 60, width: 60 }}>
                                <span className='fs-2 shadow fw-medium '>{loggedInUserName.substring(0, 1).toUpperCase()}</span>
                            </Button>
                        )}
                    </div>
                    <div className='mx-auto my-2 fs-6'>Hi, {loggedInUserName}</div>
                </div>
                <div className="mt-2 border border-middle"></div>
                <div className='mt-2'>
                    <div>
                        <Button id='btn-account' size="sm" variant="outline-none" className='me-1'>
                            <i className="bi bi-person"></i>
                        </Button>
                        <span style={{ verticalAlign: "middle" }}>Account {!isMailVerified == 1 && loginType === 0 ? <a href='#' style={{ textDecoration: 'none' }} onClick={handleSendMail}> Verify</a> : ''}</span> 
                    </div>
                    <div>
                        <Button id='btn-plan' size="sm" variant="outline-none" className='me-1'>
                            <i className="bi bi-star-fill" style={{ color: `var(--plan-${userPlan.planId}` }} ></i>
                        </Button>
                        {/* <a target="_blank" href="https://www.wowleads.com/#pricing" style={{ verticalAlign: "middle" }}>{userPlan.planName}</a>*/}
                        <span style={{ verticalAlign: "middle" }}>{userPlan.planName}{userPlan.planId > 1 ? <a href='#' style={{ textDecoration: 'none' }} className='mx-2' onClick={handlePricing}>Change Plan</a> : ''}</span>
                    </div>
                    <div>
                        <Button id='btn-credits' size="sm" variant="outline-none" className='me-1'>
                            <i className="bi bi-coin"></i>
                        </Button>
                        <span style={{ verticalAlign: "middle" }}>Credits: {credits} {userPlan.planId > 1 ?<a href='#' style={{ textDecoration: 'none' }} className='mx-2' onClick={showadditionalCreditsdModal}>Buy</a> : ''}</span>
                    </div>
                    {loginType == 0 ?
                        <div onClick={handleChangePassword}>
                            <Button id='btn-credits' size="sm" variant="outline-none" className='me-1'>
                                <i className="bi bi-pencil"></i>
                            </Button>
                            <span role="button" style={{ verticalAlign: "middle" }}>Change Password</span>
                        </div>
                        :
                        ''
                    }
                    <div className="my-2 border border-middle"></div>
                    <div>
                        <Link to={'/signin'} onClick={() => { onLogout(); closeDropdown(); }} className="text-decoration-none text-dark">
                            <div className="d-flex flex-row">
                                <Button id='btn-logout' size="sm" variant="outline-none" className="me-1">
                                    <i className="bi bi-box-arrow-right"></i>
                                </Button>
                                <div className="my-auto">Sign Out</div>
                            </div>
                        </Link>
                    </div>
                    {successMessage && (
                        <div className='text-success'><p>{successMessage}</p></div>
                    )}
                </div>

            </div>
            <div ref={changePasswordRef}>
                <Modal show={confirmUserPasswordModal} onHide={() => setConfirmUserPasswordModal(false)} animation={false} centered>
                    <Modal.Body>
                        <div className="mb-2 p-2">
                            <label htmlFor='password' className="form-label">Enter Old Password</label>
                            <div className='password-wrapper'>
                                <input
                                    required
                                    id='password'
                                    type={passwordVisible ? 'text' : 'password'}
                                    placeholder='Enter Old Password'
                                    value={oldPassword}
                                    onChange={handlePasswordChange}
                                    className="form-control mt-1"
                                />
                                <span onClick={togglePasswordVisibility} className="password-icon">
                                    {passwordVisible ? <i class="bi bi-eye-slash"></i> : <i class="bi bi-eye"></i>}
                                </span>
                            </div>
                            <div className="row justify-content-md-center mt-4">
                                    {errorMessage && (
                                        <div className='text-danger mx-auto'><p>{errorMessage}</p></div>
                                    )}
                                <div className="col-md-4 d-grid gap-2">
                                    <Button variant="outline-primary" onClick={handleConfirmPassword}>Confirm</Button>
                                </div>
                                <div className="col-md-4 d-grid gap-2">
                                    <Button variant="outline-secondary" onClick={() => setConfirmUserPasswordModal(false)}>Cancel</Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                <Modal show={showUserPasswordModal} onHide={handleUserPasswordModal} animation={false} centered>
                    <Modal.Body>

                        <ChangeUserPassword email={email} setShowUserPasswordModal={setShowUserPasswordModal} />

                    </Modal.Body>
                </Modal>

                <Modal show={pricingdModal} onHide={handlePricingModal} animation={false} centered size='xl'>
                    <Modal.Body>

                        <Pricing setPricingModal={setPricingModal}  planId={userPlan.planId}/>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary me-4" size="sm" onClick={() => setPricingModal(false)}>Cancel</Button>

                    </Modal.Footer>
                </Modal>

                <Modal show={additionalCreditsdModal} onHide={hideadditionalCreditsdModal} animation={true} centered size='md'>
                    <Modal.Body>

                        <AdditionalCredits hideadditionalCreditsdModal={hideadditionalCreditsdModal} loggedInUserId={loggedInUserId}/>

                    </Modal.Body>
                </Modal>

            </div>
            <style>{
                `
                .password-wrapper {
                    position: relative;
                }

                .password-icon {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
                `}
                
            </style>
        </div>

    );
}

export default ProfileDropdown;