import React, { Component } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const categoryTypes = [
    {ctype: 2, cname: "Selected"},
    {ctype: 1, cname: "Related"},
    {ctype: 0, cname: "Other"}
];
class LeadFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryData: this.props.catdata, 
            selectedData: [],
            relatedData: [],
            otherData: [],
            selectedCategories: [],
            selectedKeywords: '',
            previousKeywords: '',
            jobName: this.props.jname,
            editJobName: false,
            newJobName: this.props.jname,
            accordion: [false, false, true],
        };

        this.checkboxRef = [React.createRef(), React.createRef(), React.createRef()]; //Array(React.createRef(), categoryTypes.length);
        this.selectCategory = this.selectCategory.bind(this);
        this.selectCategoryType = this.selectCategoryType.bind(this);
        this.getSelectedCategories = this.getSelectedCategories.bind(this);
        this.getSelectedCategoryTotals = this.getSelectedCategoryTotals.bind(this);
        this.hasSelectedCategories = this.hasSelectedCategories.bind(this);
        this.clearSelectedCategories = this.clearSelectedCategories.bind(this);
        this.clearSelectedKeywords = this.clearSelectedKeywords.bind(this);
        this.handleSubmitKeywords = this.handleSubmitKeywords.bind(this);
        this.handleChangeKeywords = this.handleChangeKeywords.bind(this);
        this.handleApplyKeywords = this.handleApplyKeywords.bind(this);
        this.refreshCategoryData = this.refreshCategoryData.bind(this);
        this.handleEditJobName = this.handleEditJobName.bind(this);
        this.handleChangeJobName = this.handleChangeJobName.bind(this);
        this.handleEscJobName = this.handleEscJobName.bind(this);
        this.handleExitJobName = this.handleExitJobName.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
    };

    toggleAccordion(e) {
        const categoryType = parseInt(e.currentTarget.dataset.value);
        let thisAccordion = this.state.accordion;
        thisAccordion[categoryType] = !thisAccordion[categoryType];
        this.setState({accordion: thisAccordion});
    }

    refreshCategoryData() {
        this.setState({categoryData: this.props.catdata})
    }

    selectCategoryType(e) {
        const eChecked = e.target.checked;
        const selectedCategoryType = parseInt(e.currentTarget.dataset.value);
        const selectedCategories = this.getSelectedCategories(selectedCategoryType); 
        let newSelectedCategories = [];

        if (eChecked) {
            newSelectedCategories = [...this.state.selectedCategories, ...selectedCategories]
        } else {
            newSelectedCategories = this.state.selectedCategories.filter(c => (!selectedCategories.includes(c)))
        }
        this.setState({selectedCategories: newSelectedCategories});
        this.props.uscd(newSelectedCategories);
    }
        
    selectCategory(e) {
        const eChecked = e.target.checked;
        const selectedCategory = e.currentTarget.dataset.value;
        let newSelectedCategories = [];
        
        if (eChecked) {
            newSelectedCategories = [...this.state.selectedCategories, selectedCategory]
        } else {
            newSelectedCategories = this.state.selectedCategories.filter(c => (c !== selectedCategory))
        }
        this.setState({selectedCategories: newSelectedCategories});
        this.props.uscd(newSelectedCategories);
    }

    getSelectedCategories(categoryType) {
        const data = this.state.categoryData.filter(c => c.ucattype === categoryType).map(c => c.ucategory);
        return data
    }

    getSelectedCategoryTotals(categoryType) {
        const data = this.state.categoryData.filter(c => c.ucattype === categoryType).reduce((sum, c) => sum + c.count, 0);
        return data
    }

    hasSelectedCategories(categoryType) {
        const data = this.getSelectedCategories(categoryType);
        const filteredData = this.state.selectedCategories.filter(c => data.includes(c));
        const isIndeterminate = ((filteredData.length > 0) && (filteredData.length < data.length));
        if (this.checkboxRef[categoryType].current) {
            this.checkboxRef[categoryType].current.indeterminate = isIndeterminate;
        };
        return (filteredData.length > 0)
    }

    clearSelectedCategories() {
        this.setState({selectedCategories: []});
        this.props.uscd([]);
    }

    clearSelectedKeywords() {
        this.setState({selectedKeywords: ''});
        this.props.ukwd('');
    }

    handleSubmitKeywords(event) {
        event.preventDefault(); 
        event.stopPropagation();
      }

    handleChangeKeywords(event) {
        this.setState({selectedKeywords: event.target.value})
    }

    handleApplyKeywords(event) {
        if (this.state.selectedKeywords !== this.state.previousKeywords) {
            this.props.ukwd(this.state.selectedKeywords);
            this.setState({previousKeywords: this.state.selectedKeywords});
        }
    }

    handleChangeJobName(event) {
        this.setState({newJobName: event.target.value});
    }

    handleEditJobName(event) {
        if (this.props.jowner) {
            this.setState({editJobName: true})
        }
    }

    handleEscJobName(event) {
        if ((event.key === "Escape") || (event.key === "Enter")) {
            event.target.blur();
        }
    }

    async handleExitJobName(event) {
        let continueEditing = false;
        if ((this.state.newJobName !== this.state.jobName) && (/^.{1,255}$/.test(this.state.newJobName))) {
            if (window.confirm('Are you sure you want to change the list name?')) {
                try {
                    await axios.post('/updatejobname', {id: this.props.jid, name: this.state.newJobName});
                    this.setState({jobName: this.state.newJobName});
                    this.props.ssjn(this.state.newJobName);
                } catch (err) {
                    console.log(err.message);
                }
            } else {
               this.setState({newJobName: this.state.jobName});
            } 
        }
        this.setState({editJobName: continueEditing});
    }

    render() {
                
    return (
        <div className="m-0 p-0 d-flex flex-column" style={{height: "calc(100%)"}}>
            <div id="divFilterContainer" className='mb-2 px-2 py-1 border rounded shadow bg-primary fs-6 text-white' style={{cursor: "pointer"}} onClick={this.handleEditJobName}>
                {this.state.editJobName ? (<>
                    <Form.Control id="ctlJobName" as="textarea" rows={3} size="sm" value={this.state.newJobName} autoFocus={this.state.editJobName} onChange={this.handleChangeJobName} onKeyDown={this.handleEscJobName} onBlur={this.handleExitJobName} />
                    <div className="d-flex mt-1">
                        <span className={`${(/^.{1,255}$/.test(this.state.newJobName)) ? 'd-none' : 'd-flex'} me-1 text-white`}><small>List name must be between 1 to 255 characters long</small></span>
                    </div>
                </>) : (
                <span>{this.state.jobName}</span>
                )}
            </div>
            <div id="divHiddenGemsContainer" className='p-1 mb-1 border bg-light'>
                <Form id="frmHiddenKeywords" onSubmit={this.handleSubmitKeywords}>
                    <Form.Label id="lblKeywords" className="align-items-center">
                        {`Hidden Gems`}
                    </Form.Label>
                    <div className="d-flex flex-row">
                        <Form.Control id="ctlKeywords" className="border border-primary" size="sm" type="search" autoComplete="off" placeholder='Search...' value={this.state.selectedKeywords} disabled={this.props.uld} onChange={this.handleChangeKeywords}></Form.Control>
                        <Button type="submit" className="ms-1" size="sm" onClick={this.handleApplyKeywords} disabled={this.props.uld}><i class="bi bi-search"></i></Button>
                    </div>
                </Form>
            </div>
            <div id="divCategoryHeader" className="p-1 bg-light d-flex">
                <Form.Label id="lblCategories" className="w-100 my-auto py-1">
                    {`Categories`}{this.state.categoryData && (this.state.categoryData.length > 0) ? ` (${this.state.categoryData.length})` : ''}{this.props.ucd ? (<React.Fragment>&nbsp;<i className="fa fa-spinner fa-pulse"></i></React.Fragment>) : ''}
                </Form.Label>
                {/*
                <span className={`${(this.state.selectedCategories.length > 0) ? 'd-flex' : 'd-none'}`}>
                    <i className={`ms-auto my-auto h6 bi bi-trash`} style={{cursor: "pointer"}} onClick={this.clearSelectedCategories}></i>
                </span>
                */}
            </div>
            <div id="divCategoryContainer" className='p-1 flex-grow-1 shadow border border-1 border-gray' style={{overflowY: "auto", overflowX:"hidden"}} >
                {categoryTypes.map((ct) => { return (
                    (this.state.categoryData && (this.state.categoryData.length > 0) && this.state.categoryData.filter(c => c.ucattype === ct.ctype).map((row, index) => { return (
                    <React.Fragment>
                        {(index === 0) ? (
                        <div className="d-flex">
                            <i data-value={ct.ctype} className={`me-1 text-primary bi bi-caret-${this.state.accordion[ct.ctype] ? 'down' : 'right' }-fill`} style={{cursor: "pointer"}} onClick={this.toggleAccordion} disabled={this.props.uld} />
                            <Form.Check id={`cattype-${ct.ctype}`} >
                                <Form.Check.Input ref={this.checkboxRef[ct.ctype]} className="border border-1 border-primary" data-value={ct.ctype} checked={this.hasSelectedCategories(ct.ctype)} onChange={this.selectCategoryType} disabled={this.props.uld} />
                                <Form.Check.Label>{ct.cname}{this.state.accordion[ct.ctype] ? '' : ` (${this.getSelectedCategoryTotals(ct.ctype)})`}</Form.Check.Label>
                            </Form.Check>
                        </div>
                        ) : ''}
                        <Form.Check id={`cat-${ct.ctype}-${index}`} className={`ms-4 ${this.state.accordion[ct.ctype] ? 'd-block' : 'd-none'}`}>
                            <Form.Check.Input className="border border-1 border-primary" data-value={row.ucategory} checked={(this.state.selectedCategories.indexOf(row.ucategory) > -1)} onChange={this.selectCategory} disabled={this.props.uld} />
                            <Form.Check.Label>{`${row.ucategory} (${row.count})`}</Form.Check.Label>
                        </Form.Check>
                    </React.Fragment>)})
                    )                    
                )})}
            </div>
        </div>
    )}
}

export default LeadFilters;