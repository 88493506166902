// Function to set a cookie for a long duration (default 10 years)
export const setCookie = (name, value, days = 365 * 10) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Default to 10 years
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

// Function to get a cookie
export const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

// Function to delete a cookie
export const eraseCookie = (name) => {
    document.cookie = name + "=; Max-Age=-99999999;";
};

// Function to delete all cookies
export const eraseAllCookies = () => {
    const cookies = document.cookie.split("; ");
    cookies.forEach(cookie => {
        const cookieName = cookie.split("=")[0];
        document.cookie = cookieName + "=; Max-Age=-99999999;";
    });
};
