import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SocialMediaLogin from './socialmedialogin';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';

function SigninPage(props) {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [thisProps] = useState(props);
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const planId = searchParams.get('id') ? searchParams.get('id') : -1;
  const stype = searchParams.get('type');
  const period = searchParams.get('period');
  const currency = searchParams.get('currency');


  function setLoginStatus(bStatus, email, userid, planid, userimg, userplan, username, sessionid, userdata) {
    thisProps.parentCallback(bStatus, email, userid, planid, userimg, userplan, username, sessionid, userdata);
  };

  function handleEmailChange(event) {
    setEmail(event.target.value)
  };

  function handlePasswordChange(event) {
    setPassword(event.target.value)
  };

  function handleEmailFocus(event) {
    setErrorMessage(null)
  }

  function handlePasswordFocus(event) {
    setErrorMessage(null)
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setLoading(true);
      const publicIP = await axios.get('https://api.ipify.org?format=json');
      const ipInforesponse = await axios.get(`/ipInfo/${publicIP.data.ip}`)
      const ipInfo = ipInforesponse.data;
      const response = await axios.post('/signin', { email, password, ipInfo });
      if (response.status === 205) {
        // User not found, show Session popup
        setShowSessionPopup(true);
        return;
      }
      // token = response.data && response.data[0];
      const userid = response.data && response.data.userid;
      const planid = response.data && response.data.planid;
      const userplan = response.data && response.data.userplan;
      const username = response.data && response.data.username;
      const session = response.data && response.data.session;
      const userdata = response.data && response.data.userdata;
      console.log(userdata,"userdata")
      // localStorage.setItem('token', token);
      setLoginStatus(true, email, userid, planid, null, userplan, username, session, userdata);
      if(planId > 0){
        navigate(`/accountdetails?id=${planId}&type=${stype}&period=${period}&currency=${currency}`)
      }else{
      navigate('/main');
      }
    } catch (error) {
      setErrorMessage('Invalid User ID or Password');
      // setLoginStatus(false, null, null, null, null, null, null, null);
    } finally {
      setLoading(false); // Set loading state to false after the fetch
    }
  };

  const handleCreateNewSession = async () => {
    try{
        setLoading(true);
        const publicIP = await axios.get('https://api.ipify.org?format=json');
        const ipInforesponse = await axios.get(`/ipInfo/${publicIP.data.ip}`)
        const ipInfo = ipInforesponse.data;
        const response = await axios.post('/loginwithnewsession', { email, password, ipInfo });
        const userid = response.data && response.data.userid;
        const planid = response.data && response.data.planid;
        const userplan = response.data && response.data.userplan;
        const username = response.data && response.data.username;
        const session = response.data && response.data.session;
        const userdata = response.data && response.data.userdata;

        // localStorage.setItem('token', token);
        setLoginStatus(true, email, userid, planid, null, userplan, username, session, userdata);
        if(planId > 0){
          navigate(`/accountdetails?id=${planId}&type=${stype}&period=${period}&currency=${currency}`)
        }else{
        navigate('/main');
        }
    }catch (error){
        setErrorMessage('Invalid User ID or Password');
        // setLoginStatus(false, null, null, null, null, null, null, null);
    }finally {
      setLoading(false); // Set loading state to false after the fetch
    }
        
};

const togglePasswordVisibility = () => {
  setPasswordVisible(!passwordVisible);
};

  return (
    <>
    {
      showSessionPopup ? (
        <Modal show={showSessionPopup} onHide={() => setShowSessionPopup(false)} centered size='md'>
          <Modal.Header closeButton={true}>
            <h6 className='mb-0 fw-semibold'>Wowleads</h6>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>
              WowLeads is currently open in another window. Would you like to close the session in the other window and open Wowleads in this window instead?
              </label>
              <div className='mt-2 d-flex justify-content-end'>
              <Button onClick={() => setShowSessionPopup(false)} className='btn-sm me-2' variant="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleCreateNewSession}
                className={`btn-sm`}
                variant="primary"
              >
                Proceed
              </Button>
            </div>
            
            </div>
          </Modal.Body>
        </Modal>
  )
  :(
    <React.Fragment>
      <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="p-4 border shadow align-items-center" style={{ width: '350px' }}>
            <h3 className="text-center fs-4 mb-4">Sign In</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor='login_userid'>Email</Form.Label>
                <Form.Control 
                    required
                    id='login_userid'
                    type="email"
                    placeholder="Enter email"
                    value={email} 
                    onChange={handleEmailChange} 
                    onFocus={handleEmailFocus}
                    autoFocus={true}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor='login_password'>Password</Form.Label>
                <InputGroup >
                  <Form.Control 
                      required
                      id='login_password'
                      type={passwordVisible ? 'text' : 'password'}
                      placeholder='Enter Password'
                      value={password}
                      onChange={handlePasswordChange}
                      onFocus={handlePasswordFocus}
                  />
                  <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                    {passwordVisible ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <a href='/forgotPassword'>Forgot Password</a>
              <div className="d-grid mb-3 mt-2">
                <Button disabled={loading} type="submit" className="btn btn-primary">Sign In</Button>
                {errorMessage && <div className='w-100 text-danger text-center' >{errorMessage}</div>}
              </div>
            </Form>
            <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
              <hr style={{ flex: '1', borderTop: '2px solid #000' }} />
              <div style={{ margin: '0 10px', color: '#000' }}>Or</div>
              <hr style={{ flex: '1', borderTop: '2px solid #000' }} />
            </div>

            <div className='mt-2'>
              <SocialMediaLogin setLoginStatus={setLoginStatus} setErrorMessage={setErrorMessage} status={'login'} loading={loading} setLoading={setLoading}/>
            </div>
        </div>
      </div>
    </React.Fragment>
    )
  }
  </>
  );
}

export default SigninPage;