import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Badge from 'react-bootstrap/Badge';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Table from 'react-bootstrap/Table';

function Pricing({ setPricingModal, planId }) {
    const [timeradio, setTimeRadio] = useState('2');
    const [currencyradio, setCurrencyRadio] = useState('1');
    const [planData, setPlanData] = useState();
    const [planDetails, setPlanDetails] = useState();

    const tableStyle = {
        maxHeight: '26vh',
        overflowY: 'auto'
      };

    const handleGetStartedClick = (id, type) => {
        window.location.href = `/accountdetails?id=${id}&type=${type}&period=${timeradio}&currency=${currencyradio}`
    };
    const timeOptions = [
        { name: 'Monthly', value: '1' },
        { name: 'Yearly', value: '2' },
    ];

    const currencyOptions = [
        { name: 'INR', value: '1' },
        { name: 'USD', value: '2' },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/getprices/${currencyradio}`);
                setPlanData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currencyradio]);

    useEffect(() => {
        const fetchCurrency = async () => {
            try {
                const publicIP = await axios.get('https://api.ipify.org?format=json');
                const response = await axios.get(`/ipInfo/${publicIP.data.ip}`);
                const plandata = await axios.get('/getplans');
                setPlanDetails(plandata.data)
                if (response.data.ipInfo.countryCode === 'IN') {
                    setCurrencyRadio('1');
                } else {
                    setCurrencyRadio('2');
                }
            } catch (error) {
                console.error('Error fetching IP info:', error);
            }
        };

        fetchCurrency();
    }, []);

    const getFormatedPriceForPlan = (planid, type) => {
        if (planData) {
            const plan = planData.find(item => item.planid === planid);
            if (plan) {
                const price = type == 1 ? plan.monthlyfee : Math.ceil(plan.annualfee / 12);
                return price.toLocaleString('en-IN'); // Format the price with commas
            }
            return 0;
        }
        return 0;
    };
    const getAnnualTotalPriceForPlan = (planid, type) => {
        if (planData) {
            const plan = planData.find(item => item.planid === planid);
            if (plan) {
                const price = type == 1 ? plan.monthlyfee : plan.annualfee;
                return price.toLocaleString('en-IN'); // Format the price with commas
            }
            return 0;
        }
        return 0;
    };

    const getPerUserPriceForPlan = (planid, type) => {
        if (planData) {
            const plan = planData.find(item => item.planid === planid);
            if (plan) {
                const price = type == 1 ? currencyradio == 1 ? Math.ceil((plan.monthlyfee)/plan.maxusers): parseFloat(((plan.monthlyfee)/plan.maxusers).toFixed(1)) : currencyradio == 1 ? Math.ceil(((plan.annualfee)/12)/plan.maxusers) : parseFloat((((plan.annualfee)/12)/plan.maxusers).toFixed(1));
                return price.toLocaleString('en-IN'); // Format the price with commas
            }
            return 0;
        }
        return 0;
    };

    const getPlanDisabled = (planid) =>{
        if(planId == planid){
            return true;
        }else{
            return false;
        }
    }

    return (
        <>
            <Container fluid className="py-2">
                <Row className="d-flex align-items-center justify-content-center mt-4 px-4">
                    <Col xs="12" md="auto" className="d-flex justify-content-center mt-5">
                        <ButtonGroup toggle>
                            {timeOptions.map((option, idx) => (
                                <div key={idx} className="position-relative">
                                    {option.name === 'Yearly' && (
                                        <Badge
                                            pill
                                            bg="success"
                                            className="position-absolute"
                                            style={{ top: '-32px', left: '50%', transform: 'translateX(-50%)', fontSize: '0.7rem', padding: '0.5rem 0.5rem' }}
                                        >
                                            Save up to 20%
                                        </Badge>
                                    )}
                                    <ToggleButton
                                        key={idx}
                                        id={`time-option-${idx}`}
                                        type="radio"
                                        variant="outline-primary"
                                        name="timeOptions"
                                        value={option.value}
                                        checked={timeradio === option.value}
                                        onChange={(e) => setTimeRadio(e.currentTarget.value)}
                                        className="px-4"
                                        size="sm"
                                    >
                                        {option.name}
                                    </ToggleButton>
                                </div>
                            ))}
                        </ButtonGroup>
                    </Col>
                    <Col xs="12" md="auto" className="d-flex justify-content-center mt-5">
                        <ButtonGroup toggle>
                            {currencyOptions.map((option, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`currency-option-${idx}`}
                                    type="radio"
                                    variant="outline-primary"
                                    name="currencyOptions"
                                    value={option.value}
                                    checked={currencyradio === option.value}
                                    onChange={(e) => setCurrencyRadio(e.currentTarget.value)}
                                    className="px-4"
                                    size="sm"
                                >
                                    {option.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </Col>
                </Row>

                <Row className="d-flex align-items-center justify-content-center mt-4">
                    <Col className="mt-4 ">
                        <Card style={{ minWidth: "12rem" }} className="shadow">
                            <Card.Header style={{ backgroundColor: "#16a692" }}></Card.Header>
                            <Card.Body className="text-center px-0">
                                <Card.Title>Free</Card.Title>
                                <Card.Text>
                                    <h4><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>0<sapn style={{ color: 'grey', fontSize: '12px' }}>/month</sapn></h4>
                                    <p><br />
                                        <br />
                                        1 User<br />
                                        Cost/User/Mo: <span>{currencyradio === '1' ? '\u20B9' : '$'}</span>0
                                    </p>
                                    <Button style={{ backgroundColor: "#6d6c6c", border: 'none', borderRadius: '50px' }} size="sm" className="px-4 mt-2" disabled={true}>
                                        Get Started
                                    </Button>
                                </Card.Text>
                            </Card.Body>
                        </Card></Col>
                    <Col className="mt-4">
                        <Card style={{ minWidth: "12rem" }} className="shadow">
                            <Card.Header style={{ backgroundColor: "#EFA759" }}></Card.Header>
                            <Card.Body className="text-center px-0">
                                <Card.Title>Premium</Card.Title>
                                <Card.Text>
                                    <h4><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{getFormatedPriceForPlan(3, timeradio)}<sapn style={{ color: 'grey', fontSize: '12px' }}>/month</sapn></h4>
                                    <p>{timeradio == 1 ? 'Montly' : 'Annual'} Subscription fee <br /><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{timeradio == 1 ? getFormatedPriceForPlan(3, timeradio) : getAnnualTotalPriceForPlan(3, timeradio)}<br />
                                        1 User<br />
                                        Cost/User/Mo: <span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{getPerUserPriceForPlan(3, timeradio)}
                                    </p>

                                    <Button onClick={() =>  handleGetStartedClick(3, 'normal')} style={{ backgroundColor: getPlanDisabled(3)===true ? "#6d6c6c" : "#EFA759", border: 'none', borderRadius: '50px' }} size="sm" className="px-4 mt-2" disabled={getPlanDisabled(3)}>
                                        Get Started
                                    </Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="mt-4">
                        <Card style={{ minWidth: "12rem" }} className="shadow">
                            <Card.Header style={{ backgroundColor: "#CC5683" }}></Card.Header>
                            <Card.Body className="text-center px-0">
                                <Card.Title>Small Business</Card.Title>
                                <Card.Text>
                                    <h4><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{getFormatedPriceForPlan(4, timeradio)}<sapn style={{ color: 'grey', fontSize: '12px' }}>/month</sapn></h4>
                                    <p>{timeradio == 1 ? 'Montly' : 'Annual'} Subscription fee <br /><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{timeradio == 1 ? getFormatedPriceForPlan(4, timeradio) : getAnnualTotalPriceForPlan(4, timeradio)}<br />
                                        5 User<br />
                                        Cost/User/Mo: <span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{getPerUserPriceForPlan(4, timeradio)}
                                    </p>

                                    <Button onClick={() =>  handleGetStartedClick(4, 'normal')} style={{ backgroundColor: "#6d6c6c", border: 'none', borderRadius: '50px' }} size="sm" className="px-4 mt-2" disabled={true}>
                                        Coming Soon
                                    </Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="mt-4">
                        <Card style={{ minWidth: "12rem" }} className="shadow">
                            <Card.Header style={{ backgroundColor: "#634EA0" }}></Card.Header>
                            <Card.Body className="text-center px-0">
                                <Card.Title>Medium Business</Card.Title>
                                <Card.Text>
                                    <h4><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{getFormatedPriceForPlan(5, timeradio)}<sapn style={{ color: 'grey', fontSize: '12px' }}>/month</sapn></h4>
                                    <p>{timeradio == 1 ? 'Montly' : 'Annual'} Subscription fee <br /><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{timeradio == 1 ? getFormatedPriceForPlan(5, timeradio) : getAnnualTotalPriceForPlan(5, timeradio)}<br />
                                        20 User<br />
                                        Cost/User/Mo: <span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{getPerUserPriceForPlan(5, timeradio)}
                                    </p>
                                    <Button onClick={() =>  handleGetStartedClick(5, 'normal')} style={{ backgroundColor: "#6d6c6c", border: 'none', borderRadius: '50px' }} size="sm" className="px-4 mt-2" disabled={true}>
                                        Coming Soon
                                    </Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="mt-4">
                        <Card style={{ minWidth: "12rem" }} className="shadow">
                            <Card.Header style={{ backgroundColor: "#3B56AD" }}></Card.Header>
                            <Card.Body className="text-center px-0">
                                <Card.Title>Enterprise</Card.Title>
                                <Card.Text>
                                    <h4><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{getFormatedPriceForPlan(6, timeradio)}<sapn style={{ color: 'grey', fontSize: '12px' }}>/month</sapn></h4>
                                    <p>{timeradio == 1 ? 'Montly' : 'Annual'} Subscription fee <br /><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{timeradio == 1 ? getFormatedPriceForPlan(6, timeradio) : getAnnualTotalPriceForPlan(6, timeradio)}<br />
                                        100 User<br />
                                        Cost/User/Mo: <span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{getPerUserPriceForPlan(6, timeradio)}
                                    </p>

                                    <Button onClick={() =>  handleGetStartedClick(6, 'normal')} style={{ backgroundColor: "#6d6c6c", border: 'none', borderRadius: '50px' }} size="sm" className="px-4 mt-2" disabled={true}>
                                        Coming Soon
                                    </Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-4 px-4">
                    <div style={tableStyle}>
                    <Table striped bordered hover style={{ tableLayout: 'fixed' }}>
                        <thead>
                            <tr>
                                <th style={{ width:'180px', position: 'sticky', top: 0, left: 0, zIndex: 2 }}>Plan & Features</th>
                            {planDetails && planDetails.map((row, index) => (
                                <th style={{ width:'140px', position: 'sticky', top: 0, left: 0, zIndex: 1 }} key={index} className="text-center">{row.name}</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Database Login & Search Access</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">Yes</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Number of Users per Account</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">{row.maxusers}</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Number of simultaneous login sessions per user</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">1</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Max number of "business categories" per crawl list</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">5</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Max number of parallel active lead search crawling sessions running in background</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">{row.maxparallelcrawls}</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Number of Leads with Unmasked Data Included Per Crawl List Result (where available: website, telephone, email, address, Geo location, social media links, key page links (leadership, careers, etc) with matching category (rest of the results the contact data will be masked).<span style={{color:"blue"}}>Once this limit is reached per category, all subsequent results will be masked.</span></td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">{row.maxunmaskedleads}</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Max Leads Count resulting per Crawl List - masked+unmasked combined (for matching category)</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">{row.maxleadsperlist == 99999 ? 'Unlimited' : row.maxleadsperlist}</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Max unused Leads Credited per Month to Account (up to 3 months)</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">{(row.maxleadscarryforward.toLocaleString('en-IN'))}</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Crawl lists results and unmasked leads shared across users under the same account</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">{row.issharelistsnleads.data[0] === 0 ? '-' : 'Yes'}</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Lead website metatags information when available in search result - keywords, title, description</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">Yes</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Maximum number of new Lead Crawl Lists per month (new leads resulting from earlier selected category will be masked)</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">Unlimited</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Search crawling in Geography</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">Worldwide</td>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>"For Import to CRM or for Bulk Marketing: Export Unmasked Leads List to CSV, XLS File"</td>
                                    {planDetails && planDetails.map((row, index) => (
                                    <td key={index} className="text-center">Yes</td>
                                ))}
                                </tr>
                                
                            
                            
                        </tbody>
                    </Table>
                    </div>
                </Row>
                <Row className="mt-4 px-4">
                    <div style={tableStyle}>
                    <Table striped bordered hover style={{ tableLayout: 'fixed' }}>
                        <thead>
                            <tr>
                                <th style={{ width:'140px', position: 'sticky', top: 0, left: 0, zIndex: 2 }}>Subscription Rates</th>
                            {planDetails && planDetails.map((row, index) => (
                                <th style={{ width:'140px', position: 'sticky', top: 0, left: 0, zIndex: 1 }} key={index} colSpan={2} className="text-center">{row.name}</th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Monthly Subscription fee per Account</td>
                                    {planData && planData.map((row, index) => (
                                    <><td key={index} className="text-center"><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{(row.monthlyfee.toLocaleString('en-IN'))}</td> <td key={index} className="text-center"><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{(Math.ceil(row.annualfee/12)).toLocaleString('en-IN')}</td></>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Annual Subscription fee per Account</td>
                                    {planData && planData.map((row, index) => (
                                    <><td key={index} className="text-center">-</td><td key={index} className="text-center"><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{(row.annualfee).toLocaleString('en-IN')}</td></>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Efffective Monthly Cost Per User</td>
                                    {planData && planData.map((row, index) => (
                                    <><td key={index} className="text-center"><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{(Math.ceil(row.monthlyfee/row.maxusers)).toLocaleString('en-IN')}</td> <td key={index} className="text-center"><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{(Math.ceil((row.annualfee/row.maxusers)/12)).toLocaleString('en-IN')}</td></>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Cost per each additional unmasked lead </td>
                                    {planData && planData.map((row, index) => (
                                    <><td key={index} className="text-center"><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{(row.monthlyfeeperlead).toLocaleString('en-IN')}</td> <td key={index} className="text-center"><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{(row.annualfeeperlead).toLocaleString('en-IN')}</td></>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Outreach: Automatic Contact Form Submit per campaign or Send Email (per Lead)</td>
                                    {planData && planData.map((row, index) => (
                                    <><td key={index} className="text-center"><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{(row.monthlyoutreachfeeperlead).toLocaleString('en-IN')}</td> <td key={index} className="text-center"><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{(row.annualoutreachfeeperlead).toLocaleString('en-IN')}</td></>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Outreach: Growth Consultation Intro (20mins)</td>
                                    {planData && planData.map((row, index) => (
                                    <><td key={index} colSpan={2} className="text-center">{row.monthlygrowthconsultationintrofee === 0 ? 'Free' : ''}</td></>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Outreach: Growth Consultation Detailed (60-120mins)</td>
                                    {planData && planData.map((row, index) => (
                                    <><td key={index} colSpan={2} className="text-center"><span>{currencyradio === '1' ? '\u20B9' : '$'}</span>{(row.monthlygrowthconsultationdetailedfee).toLocaleString('en-IN')}</td></>
                                ))}
                                </tr>
                                <tr >
                                    <td style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>Outreach: Staffing Service</td>
                                    {planData && planData.map((row, index) => (
                                    <td key={index} colSpan={2} className="text-center">Available</td>
                                ))}
                                </tr>
                                                      
                        </tbody>
                    </Table>
                    </div>
                </Row>

                {/* <div className="d-flex col-md-12 justify-content-end mt-4">
                    <Button variant="secondary me-4" size="sm" onClick={() => setPricingModal(false)}>Cancel</Button>

                    <a href="https://wowleadstest.netlify.app/pricing" target="_blank" className="btn btn-primary btn-sm" size="sm">Know More</a>
                </div> */}

            </Container>
        </>
    );
}

export default Pricing;
