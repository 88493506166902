import React, { useEffect } from 'react';
import axios from 'axios';

function LogoutOnUnload({ sessionid }) {
  console.log(sessionid,"sessionid")
  useEffect(() => {
    // Handle beforeunload event
    const handleBeforeUnload = (event) => {
      const url = '/api/session-close-warning'; // Replace with your logout endpoint
      const data = JSON.stringify({ sessionid: sessionid });
      const blob = new Blob([data], { type: 'application/json' });
      navigator.sendBeacon(url, blob);
    };

    // Handle page load (to check if the session is still alive after refresh)
    const handlePageLoad = () => {
      console.log('Session still alive');
      axios.post('/session-still-alive', { sessionid: sessionid })
        .then(response => {
          console.log('Session still alive', sessionid);
        })
        .catch(error => {
          console.error('Failed to keep session alive', error);
        });
    };

    // Listen for beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Listen for the page load event
    window.addEventListener('load', handlePageLoad);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('load', handlePageLoad);
    };
  }, [sessionid]);

  return null;
}

export default LogoutOnUnload;
