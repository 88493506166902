import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const BLANK_URL = '/blank.html';
// const ERROR_URL = '/error.html';

class WebPage extends Component {
  constructor (props) {
    super(props);

    this.state = {
      pageLoaded: false,
      pageValid: true,
      pageEmbeddable: true,
    }

    this.pageRef = React.createRef();
    this.timeout = null;
    this.onPageInit = this.onPageInit.bind(this);
    this.onPageLoaded = this.onPageLoaded.bind(this);
    this.validateUrl = this.validateUrl.bind(this);
  }

  componentDidMount() {
    this.validateUrl();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.validateUrl()
    }
  }

  async validateUrl() {
      this.setState({
      pageLoaded: false, 
      pageValid: true, 
      pageEmbeddable: true, 
    });

    if (this.pageRef.current) {
      this.pageRef.current.src = ''
    }

    var isPageValid = false;
    var isPageEmbeddable = false;

    if (this.props.data === BLANK_URL) {
      isPageValid = true;
      isPageEmbeddable = true;
    } else {
      const url = `/api/canEmbedUrl?url=${this.props.data}`;

      await fetch(url)
      .then(httpResponse => {
        isPageValid = httpResponse.ok;
        return httpResponse.json()
      })
      .then(response => {
        isPageEmbeddable = response.embeddable;
      })
      .catch(error => {
        isPageValid = false;
        isPageEmbeddable = false;
        console.error(error);
      })
    }

    this.setState({
      pageLoaded: (!isPageEmbeddable),
      pageValid: isPageValid,
      pageEmbeddable: isPageEmbeddable,
    })

    if (isPageEmbeddable) {
      this.onPageInit();
    }
  }

  onPageInit() {
    if (this.pageRef.current) {
      this.pageRef.current.src = this.props.data;
    }

    this.timeout = setTimeout(() => {
      if (this.pageRef.current) {
        this.pageRef.current.src = ''
      }
      this.setState({ 
        pageLoaded: true, 
        pageEmbeddable: false 
      })
    }, 5000);
  }

  onPageLoaded() {
    clearTimeout(this.timeout);
    this.setState({ pageLoaded: true })
  }

  render() {
    return (
      <div className='w-100 h-100'>
        {/*
        <span>
          url = {this.props.data} | pageValid = {this.state.pageValid ? 'Yes' : 'No'} | pageEmbeddable = {this.state.pageEmbeddable ? 'Yes' : 'No'} | pageLoaded = {this.state.pageLoaded ? 'Yes' : 'No'}
        </span>
        */}
        <div className={`w-100 h-50 align-items-center justify-content-center ${this.state.pageLoaded ? 'd-none' : 'd-flex'}`}>
          <Spinner as="span" variant='primary' size="lg" animation="border" role="status" aria-hidden="true" />
        </div>
        <div className={`w-100 align-items-center justify-content-center ${(this.state.pageLoaded && !this.state.pageValid) ? 'd-flex' : 'd-none'}`}>
          <span className='fs-5 text-center'>
            We’re unable to reach this page.The website might be down.<br />Please try again later.
          </span>
            </div>
        <div className={`w-100 align-items-center justify-content-center ${(this.state.pageLoaded && this.state.pageValid && !this.state.pageEmbeddable) ? 'd-flex' : 'd-none'}`}>
          <span className='fs-5 text-center'>
            We’re unable to display this page within the app.<br />For your convenience, you can view it directly by clicking&nbsp;
            <a rel="noreferrer" target='_blank' href={this.props.data}>here</a>
          </span>
        </div>
        <div className={`w-100 ${this.state.pageLoaded && this.state.pageEmbeddable ? 'visible' : 'invisible'}`} style={{height: "100%"}}>
          <iframe 
            id={`wp-${Date.now()}`}
            ref={this.pageRef}
            title={Date.now()}
            // src={this.props.data}
            className="w-100 h-100 border border-rounded"
            sandbox="allow-same-origin allow-scripts allow-popups"
            referrerPolicy="no-referrer"
            onLoad={this.onPageLoaded}
          />
        </div>
      </div>
    );
  }
}

export default WebPage;