import React, { Component } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const MAX_CATEGORIES_PER_LIST = parseInt(process.env.REACT_APP_MAX_CATEGORIES_PER_LIST);

const components = makeAnimated({
  DropdownIndicator: null,
});

const animatedComponents = makeAnimated();

class SelectCategories extends Component {
  constructor (props) {
    super(props);

    this.refCategories = React.createRef();
    
    this.state = {
        catKey: Date.now(),
        popupCategories: false,
        keywords: [],
        categories: [],
        catgroups: [],
        inputValue: '',
        selected: [],
        selcatgroup: 9999, // -1,
        selcattext: ''
    };

    this.onKeywordChange = this.onKeywordChange.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onMenuClose = this.onMenuClose.bind(this);
    this.focusCategories = this.focusCategories.bind(this);
    this.showPopupCategories = this.showPopupCategories.bind(this);
    this.updatePopupCategories = this.updatePopupCategories.bind(this);
    // this.selectAllPopupCategories = this.selectAllPopupCategories.bind(this);
    this.unselectAllPopupCategories = this.unselectAllPopupCategories.bind(this);
    this.closePopupCategories = this.closePopupCategories.bind(this);
  };

  onInputChange = (value, e) => {
    if (e.action === 'input-change') {
        this.setState({inputValue: value});
    }
  } 

  onMenuClose() {
    this.setState({inputValue: ''})
  }

  onKeywordChange(value) {
    this.setState({
        keywords: value
    })
    this.props.updateKeywords(value);
  }

  onCategoryChange(value) {
    this.setState({selected: value})
    this.props.updateCategories(value);
  }

  focusCategories() {
    this.refCategories.current?.focus();
  }

  showPopupCategories() {
    this.setState({popupCategories: true, selcatgroup: 9999, selcattext: ''}); 
    this.props.updateCategories([]);
  }

  updatePopupCategories(currValue) {
    const currSelected = this.state.selected;
    const currOption = this.state.categories.filter(c => (c.value === currValue));
    const currIndex = currSelected.findIndex(s => (s.value === currValue));
    if (currIndex >= 0) {
      currSelected.splice(currIndex, 1)
    } else {
      currSelected.push(currOption[0]);
    }
    this.setState({selected: currSelected});
  }
  /*
  selectAllPopupCategories(e) {
    var currOptions = this.state.categories;
    if (this.state.selcatgroup !== 9999) currOptions = currOptions.filter(c => (c.categorygroupid === this.state.selcatgroup));
    if (this.state.selcattext !== '') currOptions = currOptions.filter(c => (c.label.toLowerCase().includes(this.state.selcattext.toLowerCase())));
    var currSelected = this.state.selected;
    currSelected = currSelected.concat(currOptions);
    this.setState({
      selected: currSelected,
    });
  }
  
  unselectAllPopupCategories(e) {
    var currOptions = this.state.categories;
    if (this.state.selcatgroup !== 9999) currOptions = currOptions.filter(c => (c.categorygroupid === this.state.selcatgroup));
    if (this.state.selcattext !== '') currOptions = currOptions.filter(c => (c.label.toLowerCase().includes(this.state.selcattext.toLowerCase())));
    var currSelected = this.state.selected;
    currOptions.map(c => {
      const currIndex = currSelected.findIndex(s => (s.value === c.value));
      if (currIndex >= 0) currSelected.splice(currIndex, 1)
      return true
    })
    this.setState({
      selected: currSelected,
    });
  }  
  */
  unselectAllPopupCategories(e) {
    this.setState({
      selected: [],
    });
  }  

  closePopupCategories() {
    this.setState({popupCategories: false});
    this.setState({catKey: Date.now()});
    this.props.updateCategories(this.state.selected);
  }

  componentDidMount() {
      axios.get(`/categorygroups`)
      .then(res => {
        this.setState({
          catgroups: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      })

      axios.get(`/categories`)
      .then(res => {
        this.setState({
          categories: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      })
  }

  render() {
    const plid = JSON.parse(this.props.plid);
    const iskeywords = (JSON.parse(this.props.plan.iskeywords) === 1);
    const showkeywords = this.props.updateKeywords;

    return (
      <React.Fragment>
        <Form.Group className="mb-3">
          <Form.Group as={Row} className="mb-1">
            <Col>
              <Form.Label className="d-flex h-100 align-items-center">Categories</Form.Label>
            </Col>
            <Col className='d-flex justify-content-end'>
              <Button size="sm" variant="outline-primary" className={`border border-0`} onClick={this.showPopupCategories}
                disabled={this.props.isCrawling || ((this.state.keywords.length + this.state.selected.length) >= MAX_CATEGORIES_PER_LIST)} 
              >
                <i className={`h6 bi bi-box-arrow-up-right`}></i>
              </Button>
            </Col>
          </Form.Group>
          <Select
            key={this.state.catKey}
            ref={this.refCategories} 
            className="sm"
            isMulti={true}
            backspaceRemovesValue={false}
            closeMenuOnSelect={false}
            value={this.state.selected}
            onChange={this.onCategoryChange}
            options={this.state.categories}
            components={animatedComponents}
            onInputChange={this.onInputChange}
            onMenuClose={this.onMenuClose}
            inputValue={this.state.inputValue}
            isDisabled={this.props.isCrawling}
            isOptionDisabled={() => ((this.state.keywords.length + this.state.selected.length) >= MAX_CATEGORIES_PER_LIST)}
          />
          <div className={`mt-1 text-primary ${((this.state.keywords.length + this.state.selected.length) >= MAX_CATEGORIES_PER_LIST) ? 'visible' : 'd-none'}`}>
            <small>Only 5 categories are allowed per list</small>
          </div>
        </Form.Group>
        {(showkeywords && ((plid === 1) || iskeywords)) ? (
        <Form.Group className="mb-3">
          <Form.Label>Keywords</Form.Label>
          <CreatableSelect
            className="sm"
            isMulti={true}
            backspaceRemovesValue={false}
            onChange={this.onKeywordChange}
            options={this.state.keywords}
            components={components}
            formatCreateLabel={(c) => `Add ${c}`}
            noOptionsMessage={() => "Type something and press enter..."}
            placeholder="Start typing..."
            isDisabled={this.props.isCrawling}
            isOptionDisabled={() => ((this.state.keywords.length + this.state.selected.length) >= MAX_CATEGORIES_PER_LIST)}
          />
        </Form.Group>
        ) : ''}
        <Modal show={this.state.popupCategories} scrollable={true} size='lg' className='shadow-lg' onHide={this.closePopupCategories}>
          <Modal.Header className="py-2 bg-primary text-white">
            <span className="fs-5">Select from over 4000+ business/industry categories</span>
            {/* <h4>{`${(this.state.selcatgroup >= 0) ? 'Select from over 4000+ business/industry categories' : 'Category Groups'}`}</h4> */}
          </Modal.Header>
          <Modal.Body className='d-flex flex-column overflow-hidden'>
            {/*
            <Col className={`flex-wrap ${(this.state.selcatgroup >= 0) ? 'd-none' : 'd-flex'}`}>
              <Button 
                  size='sm'
                  className='m-1 tog-btn'
                  id={9999}
                  data-id={9999}
                  type="button"
                  variant="success"
                  value={9999}
                  onClick={(e) => {this.setState({selcatgroup: 9999})}}
                >
                  Search Categories
              </Button>
              {this.state.catgroups.map((c, key) => {
                return <Button 
                  size='sm'
                  className='m-1 tog-btn'
                  id={c.label}
                  data-id={c.value}
                  type="button"
                  variant="primary"
                  value={c.value}
                  onClick={(e) => {this.setState({selcatgroup: parseInt(e.currentTarget.dataset.id)})}}
                >
                  {c.label}
                </Button>
              })}
            </Col>
            <Col className={`flex-wrap ${(this.state.selcatgroup >= 0) ? 'd-flex' : 'd-none'}`}>
            */}
            <Col className={`flex-wrap d-flex`}>
              <div className="w-100 d-flex flex-row mb-2 pe-1 bg-primary rounded" style={{height: "40px"}}>
                <Form.Control 
                  className='m-1 w-100 rounded-pill' 
                  size="sm" 
                  type="text" 
                  placeholder="Search..." 
                  autoFocus
                  value={this.state.selcattext}
                  onChange={(e) => {this.setState({selcattext: e.currentTarget.value})}}
                />
                <Form.Control 
                  id='select_cgroup' 
                  as="select" 
                  className="w-25 my-auto main-font form-select rounded-pill" 
                  style={{height: "32px"}}
                  name="cgroup" 
                  value={this.state.selcatgroup} 
                  onChange={(e) => this.setState({selcatgroup: parseInt(e.currentTarget.value)})} 
                  >
                  <option value={9999}>All</option>
                  {this.state.catgroups.map((e, key) => {  
                    return <option key={key} value={e.value}>{e.label}</option>;  
                  })}  
                </Form.Control>
              </div>
              <div className="overflow-auto" style={{height: "480px"}}>
              {((this.state.selcatgroup === 9999) ? this.state.categories : this.state.categories.filter(c => (c.categorygroupid === this.state.selcatgroup))).filter(c => (c.label.toLowerCase().includes(this.state.selcattext.toLowerCase()))).map((c, key) => {
                return <Button 
                  size='sm'
                  className={`m-1 tog-btn`}
                  id={c.label}
                  key={key}
                  data-id={c.value}
                  type="button"
                  variant={`${this.state.selected.some(s => (s.value === c.value)) ? 'primary' : 'outline-primary'}`}
                  value={c.value}
                  onClick={() => {this.updatePopupCategories(c.value)}}
                  disabled={((this.state.keywords.length + this.state.selected.length) >= MAX_CATEGORIES_PER_LIST)}
                >
                  {c.label}
                </Button>
              })}
              </div>
            </Col>
          </Modal.Body>              
          <Modal.Footer>
            <div className={`flex-fill ${(this.state.selcatgroup >= 0) ? 'd-flex' : 'd-none'}`}>
              {/*
              <Button
                size='sm'
                className='m-1 tog-btn'
                id="back-to-groups"
                type="button"
                onClick={(e) => {this.setState({selcatgroup: -1, selcattext: ''})}}
              >
                <i className='bi bi-arrow-left'></i>&nbsp;Back
              </Button>
              <Button
                size='sm'
                className={`m-1 tog-btn ${((this.state.selcatgroup === 9999) && (this.state.selcattext === '')) ? 'd-none' : 'd-flex'}`}
                id="select-all"
                onClick={this.selectAllPopupCategories}
              >
                <i className='bi bi-check-all'></i>&nbsp;Select All
              </Button>
              */}
              <a target="_blank" href="https://drive.google.com/file/d/1L3BC9wGVMy1JF0S9iO9aAiiTaYk7oIhi/view?usp=sharing" alt="Download">
                <Button id="download-pdf" size='sm'>Download <i className="bi bi-file-earmark-pdf"></i></Button>
              </a>
              <Button
                size='sm'
                // className={`m-1 tog-btn ${((this.state.selcatgroup === 9999) && (this.state.selcattext === '')) ? 'd-none' : 'd-flex'}`}
                className={`mx-1 tog-btn ${(this.state.selected.length === 0) ? 'd-none' : 'd-flex'}`}
                id="unselect-all"
                onClick={this.unselectAllPopupCategories}
              >
                <i className='bi bi-check-all'></i>&nbsp;Unselect All
              </Button>
            </div>
            <div className='d-flex'>
              <Button size='sm' variant="primary" onClick={this.closePopupCategories}>
                OK
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SelectCategories;