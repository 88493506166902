import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import InputGroup from 'react-bootstrap/InputGroup';
import Payment  from './payment';

function AdditionalCredits({ hideadditionalCreditsdModal, loggedInUserId }) {
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [planid, setPlanid] = useState(null);
    const stype = 'additional';
    const [period, setPeriod] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [credits, setCredits] = useState('');
    const [creditsAmount, setCreditsAmount] = useState(null);
    const [perCreditsAmount, setPerCreditsAmount] = useState(0);
    const [userData, setUserData] = useState({
        loggedInUserId: loggedInUserId,
        accountid: '',
        name: '',
        email: '',
        postalcode: '',
        city: '',
        country: '',
        address: '',
        phone:'',
      });
    
    const [validateCredits, setValidateCredits] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [validated, setValidated] = useState(false);
    const [userPlanData, setUserPlanData] = useState(null);
    const [minTransactionLimit, setMinTransactionLimit] = useState(null);
    const [maxTransactionLimit, setMaxTransactionLimit] = useState(null);
    const [loading, setLoading] = useState(false);
    const isValidCredits = (!validateCredits) || (/^[0-9]+$/.test(credits) && Number(credits) > 0 && Number(creditsAmount) >=  minTransactionLimit && Number(creditsAmount) <=  maxTransactionLimit);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/getadditionalcreaditsdata/${loggedInUserId}`);
                if(response.status === 200){
                    setPlanid(0);
                    setPeriod(response.data[0].fee_type === "monthly" ? 1 : 2);
                    setCurrency(response.data[0].currencyid)
                    setPerCreditsAmount(response.data[0].fee_type === "monthly" ? response.data[0].monthlyfeeperlead : response.data[0].annualfeeperlead)
                    setMinTransactionLimit(response.data[0].minfee);
                    setMaxTransactionLimit(response.data[0].maxfee);
                }
                const accountData = await axios.get(`/account/${loggedInUserId}`);
                setUserData(prevState => ({
                    ...prevState,
                    accountid : accountData.data[0].id,
                    name: accountData.data[0].name,
                    email: accountData.data[0].email,
                    postalcode: accountData.data[0].postalcode,
                    city: accountData.data[0].city,
                    address: accountData.data[0].address,
                    country : accountData.data[0].country,
                    phone : accountData.data[0].phone
                }));

                const userData = await axios.get(`/user/${loggedInUserId}`);
                setUserPlanData(userData.data);
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    async function handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false || !isValidCredits) {
            event.stopPropagation();
            setValidated(true);
            return;
        }else{
            setLoading(true);
            try {
                setShowPaymentForm(true);
            } catch (error) {
                console.log(error)
            }finally {
                setLoading(false); 
            }
        }
    };

    function resetErrorMessage() {
        setErrorMessage(null);
    }

    function handleCreditsChange(event) {
        const newCredits = event.target.value;
        const totalprice = perCreditsAmount*newCredits;
        setCreditsAmount(currency == 1 ? parseFloat(totalprice+totalprice*0.18).toFixed(2) : parseFloat(totalprice).toFixed(1))
        setCredits(newCredits);
        setValidateCredits(true);
    };
    
    return (
        <>
        <div className="h-75 d-flex align-items-center justify-content-center">
            <div className='p-3' style={{ width: '480px' }}>
                <h3 className="text-center fs-4 mb-4">Buy Credits</h3>
                <span>Hi <b>{userData && userData.name}, </b></span><br />
                <span>You are currently on the {userPlanData && userPlanData[0].plan} plan</span><br />
                <span><b>Price Per Credit : </b>{currency === 1 ? '\u20B9' : '$'}{perCreditsAmount}</span><br />

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="py-3" controlId="formBasicCredits">
                        <Form.Label>Number Of Credits</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control 
                                required
                                type="text" 
                                placeholder="Enter Number Of Credits" 
                                value={credits}
                                onChange={handleCreditsChange}
                                onFocus={resetErrorMessage}
                                isInvalid={validateCredits && !isValidCredits}
                                className="col-md-8"
                            />
                            <span className="col-md-4 mx-4 mt-2"><b>Total Price : </b>{currency === 1 ? '\u20B9' : '$'}{creditsAmount && creditsAmount.toLocaleString('en-IN')} <br />{currency === 1 ? 'Price includes 18% GST.' : ''}</span>
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid number. The amount should be between {minTransactionLimit} and {maxTransactionLimit && maxTransactionLimit.toLocaleString('en-IN')}.
                            </Form.Control.Feedback>
                        </InputGroup>
                        {/* <Form.Text className="text-muted">
                                 { currency == 1 ? 'Transaction limit upto ₹1,00,000' : ''}
                            </Form.Text> */}
                    </Form.Group>
                    {loading && <div className='w-100 text-success text-center' >Processing, please wait...</div>}

                    <div className="row justify-content-end mt-4">
                    <div className="col-md-4 d-grid">
                    <Button variant="primary" type="submit" disabled={loading}>
                        Buy
                    </Button>
                    </div>
                    <div className="col-md-4 d-grid ">
                    <Button variant="secondary" onClick={hideadditionalCreditsdModal}>
                        Cancel
                    </Button>
                    </div>
                    </div>
                </Form>
            </div>
        </div>
        {errorMessage && <div className='w-100 text-danger text-center'>{errorMessage}</div>}
        {showPaymentForm && <Payment showPaymentForm={true} userData={userData} setShowPaymentForm={setShowPaymentForm} planid={planid} stype={stype} period={period} currency={currency} creditsAmount={creditsAmount} hideadditionalCreditsdModal={hideadditionalCreditsdModal} credits={credits}/>}
        </>
    );
}

export default AdditionalCredits;
