import React, { useEffect, useState } from 'react';
import {Form, InputGroup, Button} from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

function PeopleFilters(props) {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [inputCategory, setInputCategory] = useState('');
    const [countries, setCountries] = useState([]);
    const [inputCountry, setInputCountry] = useState('');
    const [selectedCountries, setSelectedCountries] = useState(0);
    const [textValues, setTextValues] = useState({});
    const [checkValues, setCheckValues] = useState({});
    const [toggleValues, setToggleValues] = useState({});
    const [filterModel, setFilterModel] = useState({});
    const [clearAllVisible, setClearAllVisible] = useState(false);

    function handleTextChange(e) {
        const eDataValue = e.currentTarget.dataset.value;
        const eValue = e.target.value;

        setTextValues((prevData) => ({
            ...prevData,
            [eDataValue]: eValue,
        }))

        setClearAllVisible(true);
    }

    function handleTextKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleTextClick(e);
        }
    }

    function handleTextClick(e) {
        const eDataType = e.currentTarget.dataset.type;
        const eDataValue = e.currentTarget.dataset.value;
        const eValue = textValues[eDataValue];

        if (eDataValue === 'keywords') {
            props.ppkw(eValue)
        } else {
            var filterValue = {}; 
            if (eDataType === 'text') {
                filterValue = {filterType: "text", type: "contains", filter: eValue}
            } 
            setFilterModel((prevData) => ({
                ...prevData,
                [eDataValue]: filterValue,
            }));
        }
    }

    function handleCheckChange(e) {
        const eDataType = e.currentTarget.dataset.type;
        const eDataToggle = JSON.parse(e.currentTarget.dataset.toggle);
        const eDataValue = e.currentTarget.dataset.value;
        const eChecked = e.target.checked;

        var checkValue = false;
        var toggleValue = false;
        var filterValue = {}; 
        if (eDataToggle) {
            checkValue = true;
            toggleValue = eChecked;
            if (eDataType === "number") {
                filterValue = {filterType: "number", type: "equals", filter: eChecked ? 1 : 0}
            } else if (eDataType === "text") {
                filterValue = {filterType: "text", type: eChecked ? "notBlank" : "blank"}
            }
        } else if (eChecked) {
            checkValue = eChecked;
            toggleValue = eChecked;
            if (eDataType === "number") {
                filterValue = {filterType: "number", type: "equals", filter: 1}
            } else if (eDataType === "text") {
                filterValue = {filterType: "text", type: "notBlank"}
            }
        }

        setCheckValues((prevData) => ({
            ...prevData,
            [eDataValue]: checkValue,
        }))

        setToggleValues((prevData) => ({
            ...prevData,
            [eDataValue]: toggleValue,
        }))

        setFilterModel((prevData) => ({
            ...prevData,
            [eDataValue]: filterValue,
          }));
          setClearAllVisible(true);
        }

    function resetFilterModel() {
        props.ppc([]);
        props.ppco([]);
        props.ppkw('');

        setSelectedCategories([]);    
        setSelectedCountries([]);
        setFilterModel({});
        setTextValues(Object.fromEntries(Object.keys(textValues).map(key => [key, ""])));
        setCheckValues({});
        setToggleValues({});
        setClearAllVisible(false);
    }

    useEffect(() => {
        props.ppf(filterModel)
    }, [filterModel])

    function onCategoryChange(o) {
        setSelectedCategories(o);
        props.ppc(o.map(c => c.label));
        setClearAllVisible(true);
    }

    function onCategoryInputChange(value, e) {
        if (e.action === 'input-change') {
            setInputCategory(value)
        }
    }

    function onCategoryMenuClose() {
        setInputCategory('')
    }

    useEffect(() => {
        axios.get(`/categories`)
        .then(res => {
            setCategories(res.data)
        })
        .catch((error) => {
            console.log(error);
        })

        axios.get(`/countries`)
        .then(res => {
            setCountries(res.data)
        })
        .catch((error) => {
            console.log(error);
        })
    }, [])

    function onCountryChange(o) {
        setSelectedCountries(o);
        props.ppco(o.map(c => c.code));
        setClearAllVisible(true);
    }

    function onCountryInputChange(value, e) {
        if (e.action === 'input-change') {
            setInputCountry(value)
        }
    }

    function onCountryMenuClose() {
        setInputCountry('')
    }

    return (<>
        <div className="p-2 border border-gray shadow">
            <div className={`w-100 justify-content-end d-flex ${clearAllVisible ? 'd-flex' : 'd-none'}`} style={{color: "#ff3f6c", fontWeight: "700", fontSize: "13px", cursor: "pointer"}} onClick={resetFilterModel}>CLEAR ALL</div>
            <Form id="frmPeople">
                <div className="mb-2">
                    <Form.Label id="lblCategory" for="select_category">Category</Form.Label>
                    <Select
                        id='select_category'
                        className="sm border border-primary rounded"
                        isMulti={true}
                        isClearable={true}
                        backspaceRemovesValue={true}
                        closeMenuOnSelect={false}
                        value={selectedCategories}
                        onChange={onCategoryChange}
                        options={categories}
                        components={animatedComponents}
                        onInputChange={onCategoryInputChange}
                        onMenuClose={onCategoryMenuClose}
                        inputValue={inputCategory}
                        isDisabled={false}
                        isOptionDisabled={() => false}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
                <div className="mb-2">
                    <Form.Label id="lblCountry" for="select_country">Country</Form.Label>
                    <Select
                        id='select_country'
                        className="sm border border-primary rounded"
                        isMulti={true}
                        isClearable={true}
                        backspaceRemovesValue={true}
                        closeMenuOnSelect={false}
                        value={selectedCountries}
                        onChange={onCountryChange}
                        options={countries}
                        components={animatedComponents}
                        onInputChange={onCountryInputChange}
                        onMenuClose={onCountryMenuClose}
                        inputValue={inputCountry}
                        isDisabled={false}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
                <div className="mb-2">
                    <Form.Label id="lblPeopleState" for="ctlPeopleState">State</Form.Label>
                    <InputGroup>
                        <Form.Control size="sm" id="ctlPeopleState" data-value="state" data-type="text" className="border border-primary" type="search" autoComplete="new-password" value={textValues.state} onChange={handleTextChange} onKeyDown={handleTextKeyDown}></Form.Control>
                        <Button size="sm" id="btnPeopleState" data-value="state" data-type="text" className="ms-1 rounded" onClick={handleTextClick}><i class="bi bi-search"></i></Button>
                    </InputGroup>
                </div>
                <div className="mb-2">
                    <Form.Label id="lblPeopleCity" for="ctlPeopleCity">City</Form.Label>
                    <InputGroup>
                        <Form.Control size="sm" id="ctlPeopleCity" data-value="city" data-type="text" className="border border-primary" type="search" autoComplete="new-password" value={textValues.city} onChange={handleTextChange} onKeyDown={handleTextKeyDown}></Form.Control>
                        <Button size="sm" id="btnPeopleCity" data-value="city" data-type="text" className="ms-1 rounded" onClick={handleTextClick}><i class="bi bi-search"></i></Button>
                    </InputGroup>
                </div>
                <div className="mb-2">
                    <Form.Label id="lblKeywords" for="ctlKeywords">Hidden Gems</Form.Label>
                    <InputGroup>
                        <Form.Control size="sm" id="ctlKeywords" data-value="keywords" data-type="text" className="border border-primary" type="search" autoComplete="new-password" value={textValues.keywords} onChange={handleTextChange} onKeyDown={handleTextKeyDown}></Form.Control>
                        <Button size="sm" id="btnKeywords" data-value="keywords" data-type="text" className="ms-1 rounded" onClick={handleTextClick}><i class="bi bi-search"></i></Button>
                    </InputGroup>
                </div>
                <div className="my-2"><Form.Label id="lblPeopleGroup" className="p-1 rounded bg-primary text-white w-100 fs-6">People</Form.Label></div>
                <div className="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlPersonName" data-value="p_name" data-toggle={false} data-type="text" className="border border-primary" type="checkbox" checked={checkValues.p_name} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblPersonName" for="ctlPersonName">Show People Only</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsPersonName" data-value="p_name" data-toggle={true} data-type="text" className="ms-auto" type="switch" checked={toggleValues.p_name} disabled={!checkValues.p_name} onChange={handleCheckChange} />
                </div>
                <div className="mb-2">
                    <Form.Label id="lblPeopleTitle" for="ctlPeopleTitle">Title</Form.Label>
                    <InputGroup>
                        <Form.Control size="sm" id="ctlPeopleTitle" data-value="p_role" data-type="text" className="border border-primary" type="search" autoComplete="new-password" value={textValues.p_role} onChange={handleTextChange} onKeyDown={handleTextKeyDown}></Form.Control>
                        <Button size="sm" id="btnPeopleTitle" data-value="p_role" data-type="text" className="ms-1 rounded" onClick={handleTextClick}><i class="bi bi-search"></i></Button>
                    </InputGroup>
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlPersonLinkedIn" data-value="p_linkedin" data-toggle={false} data-type="text" className="border border-primary" type="checkbox" checked={checkValues.p_linkedin} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblPersonLinkedIn" for="ctlPersonLinkedIn">LinkedIn</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsPersonLinkedIn" data-value="p_linkedin" data-toggle={true} data-type="text" className="ms-auto" type="switch" checked={toggleValues.p_linkedin} disabled={!checkValues.p_linkedin} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlPersonPhone" data-value="p_phone" data-toggle={false} data-type="text" className="border border-primary" type="checkbox" checked={checkValues.p_phone} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblPersonPhone" for="ctlPersonPhone">Phone Number</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsPersonPhone" data-value="p_phone" data-toggle={true} data-type="text" className="ms-auto" type="switch" checked={toggleValues.p_phone} disabled={!checkValues.p_phone} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlPersonEmail" data-value="p_email" data-toggle={false} data-type="text" className="border border-primary" type="checkbox" checked={checkValues.p_email} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblPersonEmail" for="ctlPersonEmail">Person Email</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsPersonEmail" data-value="p_email" data-toggle={true} data-type="text" className="ms-auto" type="switch" checked={toggleValues.p_email} disabled={!checkValues.p_email} onChange={handleCheckChange} />
                </div>
                <div className="my-2"><Form.Label id="lblWebSiteGroup" className="p-1 rounded bg-primary text-white w-100 fs-6">Web Site</Form.Label></div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlCareers" data-value="iscareerspage" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.iscareerspage} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblCareers" for="ctlCareers">Careers Page</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsCareers" data-value="iscareerspage" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.iscareerspage} disabled={!checkValues.iscareerspage} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlLeadership" data-value="isleadershippage" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.isleadershippage} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblLeadership" for="ctlLeadership">Team Page</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsTeam" data-value="isleadershippage" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.isleadershippage} disabled={!checkValues.isleadershippage} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlInvestor" data-value="ispubliccompany" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.ispubliccompany} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblInvestor" for="ctlInvestor">Investor Page</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsInvestor" data-value="ispubliccompany" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.ispubliccompany} disabled={!checkValues.ispubliccompany} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlProduct" data-value="isproductcompany" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.isproductcompany} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblProduct" for="ctlProduct">Product(s) Page</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsProduct" data-value="isproductcompany" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.isproductcompany} disabled={!checkValues.isproductcompany} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlCart" data-value="isshoppingcart" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.isshoppingcart} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblCart" for="ctlCart">Shopping Cart</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsCart" data-value="isshoppingcart" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.isshoppingcart} disabled={!checkValues.isshoppingcart} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlApp" data-value="ismobileapp" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.ismobileapp} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblApp" for="ctlApp">Mobile App</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsApp" data-value="ismobileapp" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.ismobileapp} disabled={!checkValues.ismobileapp} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlAbout" data-value="isaboutpage" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.isaboutpage} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblAbout" for="ctlAbout">About Page</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsAbout" data-value="isaboutpage" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.isaboutpage} disabled={!checkValues.isaboutpage} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlContactPage" data-value="iscontactpage" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.iscontactpage} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblContactPage" for="ctlContactPage">Contact Page</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsContactPage" data-value="iscontactpage" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.iscontactpage} disabled={!checkValues.iscontactpage} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlContactForm" data-value="isformonsite" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.isformonsite} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblContactForm" for="ctlContactForm">Contact Form</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsContactForm" data-value="isformonsite" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.isformonsite} disabled={!checkValues.isformonsite} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlPhone" data-value="isphone" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.isphone} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblPhone" for="ctlPhone">Phone Number</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsPhone" data-value="isphone" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.isphone} disabled={!checkValues.isphone} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlEmail" data-value="isemail" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.isemail} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblEmail" for="ctlEmail">Email</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsEmail" data-value="isemail" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.isemail} disabled={!checkValues.isemail} onChange={handleCheckChange} />
                </div>
                <div className="my-2"><Form.Label id="lblSocialMediaGroup" className="p-1 rounded bg-primary text-white w-100 fs-6">Social Media</Form.Label></div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlLinkedIn" data-value="islinkedinurl" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.islinkedinurl} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblLinkedIn" for="ctlLinkedIn">LinkedIn</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsLinkedIn" data-value="islinkedinurl" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.islinkedinurl} disabled={!checkValues.islinkedinurl} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlFacebook" data-value="isfacebookurl" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.isfacebookurl} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblFacebook" for="ctlFacebook">Facebook</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsFacebook" data-value="isfacebookurl" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.isfacebookurl} disabled={!checkValues.isfacebookurl} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlTwitter" data-value="istwitterurl" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.istwitterurl} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblTwitter" for="ctlTwitter">X (Twitter)</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsTwitter" data-value="istwitterurl" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.istwitterurl} disabled={!checkValues.istwitterurl} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlInstagram" data-value="isinstagramurl" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.isinstagramurl} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblInstagram" for="ctlInstagram">Instagram</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsInstagram" data-value="isinstagramurl" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.isinstagramurl} disabled={!checkValues.isinstagramurl} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlYouTube" data-value="isyoutubeurl" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.isyoutubeurl} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblYouTube" for="ctlYouTube">YouTube</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsYouTube" data-value="isyoutubeurl" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.isyoutubeurl} disabled={!checkValues.isyoutubeurl} onChange={handleCheckChange} />
                </div>
                <div class="d-flex flex-row">
                    <Form.Check>
                        <Form.Check.Input id="ctlPinterest" data-value="ispinteresturl" data-toggle={false} data-type="number" className="border border-primary" type="checkbox" checked={checkValues.ispinteresturl} onChange={handleCheckChange} />
                        <Form.Check.Label id="lblPinterest" for="ctlPinterest">Pinterest</Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="ctlIsPinterest" data-value="ispinteresturl" data-toggle={true} data-type="number" className="ms-auto" type="switch" checked={toggleValues.ispinteresturl} disabled={!checkValues.ispinteresturl} onChange={handleCheckChange} />
                </div>
            </Form>
        </div>
    </>)
}

export default PeopleFilters;