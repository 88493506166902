import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import L, {LatLngBounds} from "leaflet";
import "leaflet.markercluster";

const LeafletMaps = ({ job, containerId, activeLeadTab, data }) => {
  const mapRef = useRef(null);
  const markerClusterRef = useRef(null);
  const [updatingMapData, setUpdatingMapData] = useState(false);

  useEffect(() => {
    mapRef.current = L.map(containerId, {minZoom: 1, maxZoom: 18}).setView([0, 0], 3); 

    L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png", {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      maxZoom: 18,
      minZoom: 1,
    }).addTo(mapRef.current);

    markerClusterRef.current = L.markerClusterGroup({
      spiderfyOnMaxZoom: false, // Disable spiderfication on max zoom
    })
    
    return () => {
      markerClusterRef.current.remove();
      mapRef.current.off();
      // mapRef.current.remove(); // SK-20250114 - commented this line to prevent the irritating unhandled exception
    }
  }, [containerId])

  useEffect(() => {
    async function fetchData() {
      return await new Promise(async(resolve, reject) => {
        try {
          setUpdatingMapData(true);
          const response = await axios.post('/mapData', { jobid: job });
          const apiData = response.data;
          const newMarkers = apiData.map((item) => ({
            id: item.id,
            position: [item.latitude??0, item.longitude??0],
            content: item.name,
          }));
          setUpdatingMapData(false);
          resolve(newMarkers);
        } catch (err) {
          console.error('Error fetching data:', err);
        }
      })
    }

    try {
      fetchData()
      .then((markers) => {
        if (markers && markers.length > 0) {
          // Clear existing layers
          markerClusterRef.current.clearLayers();
        
          const customIcon = new L.Icon({
            iconUrl: 'map-pin.png', 
            iconSize: [20, 20],
            iconAnchor: [16, 32],
            popupAnchor: [0, -32],
          });

          const selectedIcon = new L.Icon({
            iconUrl: 'map-pin-selected.png', 
            iconSize: [24, 24],
            iconAnchor: [16, 32],
            popupAnchor: [0, -32],
          });
          
          const selectedLeadId = data && (data.length > 0) ? data[0].id : 0;
          
          const bounds = new LatLngBounds();
          markers.forEach((markerData) => {
            const marker = L.marker(markerData.position, { icon: (markerData.id === selectedLeadId) ? selectedIcon : customIcon });
            marker.bindTooltip(`${markerData.content}`);
            markerClusterRef.current.addLayer(marker);
            bounds.extend(markerData.position);
          })

          mapRef.current.addLayer(markerClusterRef.current);
          // mapRef.current.fitBounds(bounds);
          // Highlight the marker if condition is met
          let targetMarker = markers[0].position; // Default to the first marker's position

          if (containerId === 'tabMapContainer') {
            const matchingMarker = markers.find(marker => marker.id === selectedLeadId);
            if (matchingMarker) {
              targetMarker = matchingMarker.position;
            }
            mapRef.current.setView(targetMarker, 20);
          }else{
            mapRef.current.setView(targetMarker, 3);
          }
        }
      })
    } catch (err) {
      console.log(err)
    }
  }, [job, containerId, data])

  useEffect(() => {
    mapRef.current?.invalidateSize();
  }, [activeLeadTab]);
  
  return (
    <React.Fragment>
      {updatingMapData ? (
      <div style={{position: 'fixed', top: '50%', left: '50%', zIndex: '9999'}}>
        <i className="fs-1 fa fa-spinner fa-pulse text-dark"></i>
      </div>
      ) : ''}
      <div id={containerId} style={{ height: "100%" }}></div>
    </React.Fragment>
  );
};

export default LeafletMaps;