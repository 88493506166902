import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import AdminPaymentForm from './adminpaymentform';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import ChangeUserPassword from './changeUserPassword';
import AGgridListbox from './aggridlistbox';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

//===== variables to change =====
const serverApiUrl = '/api/adminDashboard';

const colDefUserSessions = [
  {
    field: "id",
    headerName: "Id",
    filter: 'agNumberColumnFilter',
    hide: true,
    width: 100,
  },
  {
    field: "name",
    headerName: "Name",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    filter: 'agTextColumnFilter',
    width: 300,
  },
  {
    field: "plan",
    headerName: "Plan",
    filter: 'agTextColumnFilter',
    width: 150,
    cellStyle: { textAlign: 'left' },
  },
  {
    field: "login_time",
    headerName: "Login Time",
    filter: 'agDateColumnFilter',
    width: 300,
    sort: 'desc',
    sortIndex: 0,
  },
  {
    field: "logout_time",
    headerName: "Logout Time",
    filter: 'agDateColumnFilter',
    width: 300,
  },
  {
    field: "user_ip",
    headerName: "User IP",
    filter: 'agTextColumnFilter',
    width: 300,
  },
  {
    field: "country",
    headerName: "Country",
    filter: 'agTextColumnFilter',
    width: 300,
  },
  {
    field: "latitude",
    headerName: "Latitude",
    filter: 'agTextColumnFilter',
    width: 300,
  },
  {
    field: "longitude",
    headerName: "Longitude",
    filter: 'agTextColumnFilter',
    width: 300,
  },
  {
    field: "browser",
    headerName: "Browser",
    filter: 'agTextColumnFilter',
    width: 300,
  },
  {
    field: "os",
    headerName: "Operating System",
    filter: 'agTextColumnFilter',
    width: 300,
  },
  
];

const colDefUsers = [
  {
    field: "id",
    headerName: "Id",
    filter: 'agNumberColumnFilter',
    hide: true,
    width: 200,
  },
  {
    field: "firstname",
    headerName: "First Name",
    filter: 'agTextColumnFilter',
    width: 200,
    sort: 'asc',
    sortIndex: 0,
  },
  {
    field: "lastname",
    headerName: "Last Name",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "company",
    headerName: "Company",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "phone",
    headerName: "Phone",
    filter: 'agNumberColumnFilter',
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    filter: 'agTextColumnFilter',
    width: 300,
  },
  {
    field: "country",
    headerName: "Country",
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    field: "logintype",
    headerName: "Login Type",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "plan",
    headerName: "Plan",
    filter: 'agTextColumnFilter',
    width: 200,
    cellStyle: { textAlign: 'left' },
  },
  {
    field: "iskeywords",
    headerName: "Keywords Allowed",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "isformcampaigns",
    headerName: "Form Campaigns Allowed",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "isemailcampaigns",
    headerName: "Email Campaigns Allowed",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "isactive",
    headerName: "Is Active",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "createdDate",
    headerName: "Created Date",
    filter: 'agDateColumnFilter',
    width: 300,
  },
  {
    field: "modifiedDate",
    headerName: "Modified Date",
    filter: 'agDateColumnFilter',
    width: 300,
  },
];

const colDefPostalCodes = [
  {
    field: "country_id",
    headerName: "Country Id",
    filter: 'agNumberColumnFilter',
    width: 100,
    cellStyle: { textAlign: 'right' },
  },
  {
    field: "country_name",
    headerName: "Country Name",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "state_id",
    headerName: "State Id",
    filter: 'agNumberColumnFilter',
    width: 150,
    cellStyle: { textAlign: 'right' },
  },
  {
    field: "state_name",
    headerName: "State Name",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "county_id",
    headerName: "County Id",
    filter: 'agNumberColumnFilter',
    width: 150,
    cellStyle: { textAlign: 'right' },
  },
  {
    field: "county_name",
    headerName: "County Name",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "city_id",
    headerName: "City Id",
    filter: 'agNumberColumnFilter',
    width: 150,
    cellStyle: { textAlign: 'right' },
  },
  {
    field: "city_name",
    headerName: "City Name",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "code",
    headerName: "Code",
    filter: 'agTextColumnFilter',
    width: 200,
  },
];

const colDefsallCountries = [
  {
    field: "id",
    headerName: "Id",
    filter: 'agNumberColumnFilter',
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    filter: 'agTextColumnFilter',
    width: 300,
    sort: 'asc',
    sortIndex: 0,
  },
  {
    field: "a2_code",
    headerName: "A2 Code",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "a3_code",
    headerName: "A3 Code",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "un_code",
    headerName: "UN Code",
    filter: 'agNumberColumnFilter',
    width: 200,
    cellStyle: { textAlign: 'right' },
  },
  {
    field: "geo_level1",
    headerName: "Geo Level 1",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "geo_level2",
    headerName: "Geo Level 2",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "is_active",
    headerName: "Is Active ",
    filter: 'agTextColumnFilter',
    width: 150,
  }
];

const colDefsPayments = [
  {
    field: "id",
    headerName: "Account Payment Id",
    filter: 'agTextColumnFilter',
    width: 200,
    sortIndex: 0,
    hide: true
  },
  {
    field: "name",
    headerName: "Name",
    filter: 'agTextColumnFilter',
    width: 200,
    sortIndex: 0,
  }, {
    field: "amount",
    headerName: "Amount",
    filter: 'agNumberColumnFilter',
    width: 200,
    sortIndex: 0,
  }, {
    field: "paymentmode",
    headerName: "Paymentmode",
    filter: 'agTextColumnFilter',
    width: 200,
    sortIndex: 0,
  }, {
    field: "paymentreferenece",
    headerName: "Paymentreferenece",
    filter: 'agTextColumnFilter',
    width: 200,
    sortIndex: 0,
  }, {
    field: "notes",
    headerName: "Notes",
    filter: 'agTextColumnFilter',
    width: 200,
    sortIndex: 0,
  }, {
    field: "noofcredits",
    headerName: "Noofcredits",
    filter: 'agNumberColumnFilter',
    width: 200,
    sortIndex: 0,
  }, {
    field: "createdby",
    headerName: "Createdby",
    filter: 'agTextColumnFilter',
    width: 200,
    sortIndex: 0,
  }, {
    field: "createddate",
    headerName: "Createddate",
    filter: 'agDateColumnFilter',
    width: 200,
    sort: 'desc',
    sortIndex: 0,
  }, {
    field: "modifiedby",
    headerName: "Modifiedby",
    filter: 'agTextColumnFilter',
    width: 200,
    sortIndex: 0,
  }, {
    field: "modifieddate",
    headerName: "Modifieddate",
    filter: 'agDateColumnFilter',
    width: 200,
    sortIndex: 0,
  }, {
    field: "isactive",
    headerName: "Isactive",
    filter: 'agTextColumnFilter',
    width: 200,
    sortIndex: 0,
  }
];

const colDefsCredits = [
  {
    field: "userid",
    headerName: "User Id",
    filter: 'agNumberColumnFilter',
    width: 200,
    sortIndex: 0,
  },
  {
    field: "name",
    headerName: "Name",
    filter: 'agTextColumnFilter',
    width: 200,
    sortIndex: 0,
  }, {
    field: "credits",
    headerName: "Credits",
    filter: 'agNumberColumnFilter',
    width: 200,
    sortIndex: 0,
  }
];

const colDefsActivities = [
  {
    field: "id",
    headerName: "Id",
    filter: 'agNumberColumnFilter',
    width: 200,
  },
  {
    field: "userid",
    headerName: "User Id",
    filter: 'agNumberColumnFilter',
    hide: true,
    width: 200,
  },
  {
    field: "u_name_email",
    headerName: "User",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "status_message",
    headerName: "Status",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "starttime",
    headerName: "Start Time",
    filter: 'agDateColumnFilter',
    sort: 'desc',
    sortIndex: 0,
    width: 200,
  },
  {
    field: "endtime",
    headerName: "End Time",
    filter: 'agDateColumnFilter',
    width: 200,
  },
  {
    field: "name",
    headerName: "Name",
    filter: 'agTextColumnFilter',
    width: 300,
  },
  {
    field: "keywords",
    headerName: "Keywords",
    filter: 'agTextColumnFilter',
    width: 200,
  },
  {
    field: "catnames",
    headerName: "Category",
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    field: "locgroupname",
    headerName: "Location Group Name",
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    field: "countryname",
    headerName: "Country Name",
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    field: "statename",
    headerName: "State Name",
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    field: "isstateall",
    headerName: "Is State All",
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    field: "countyname",
    headerName: "County name",
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    field: "iscountyall",
    headerName: "Is County All",
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    field: "pcodenames",
    headerName: "Postal Codes",
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    field: "ispcodeall",
    headerName: "Is Postal Codes All",
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    field: "pcodecount",
    headerName: "Postal Codes Count",
    filter: 'agNumberColumnFilter',
    width: 100,
  },
  {
    field: "keywordcount",
    headerName: "Keywords Count",
    filter: 'agNumberColumnFilter',
    width: 100,
  },
  {
    field: "categorycount",
    headerName: "Category Count",
    filter: 'agNumberColumnFilter',
    width: 100,
  },
  {
    field: "pcodescrawled",
    headerName: "Postal Codes Crawled",
    filter: 'agNumberColumnFilter',
    width: 100,
  },
  {
    field: "leadsfound",
    headerName: "Leads Found",
    filter: 'agNumberColumnFilter',
    width: 100,
  },
  {
    field: "leadscrawled",
    headerName: "Site Scan Completed",
    filter: 'agNumberColumnFilter',
    width: 100,
  },
  {
    field: "catcounts",
    headerName: "Category Counts",
    filter: 'agTextColumnFilter',
    width: 100,
  },
  {
    field: "maxparallelcrawls",
    headerName: "Max Parallel Crawl",
    filter: 'agNumberColumnFilter',
    width: 150,
  },
  {
    field: "maxunmaskedleads",
    headerName: "Max Unmasked Leads",
    filter: 'agNumberColumnFilter',
    width: 150,
  },
  {
    field: "maxleadsperlist",
    headerName: "Max Leads Per List",
    filter: 'agNumberColumnFilter',
    width: 150,
  },
  {
    field: "shared_with_users",
    headerName: "Shared With",
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    field: "is_active",
    headerName: "Is Active",
    filter: 'agTextColumnFilter',
    width: 150,
  },
  {
    field: "lockedcount",
    headerName: "Locked Leads",
    filter: 'agNumberColumnFilter',
    width: 100,
  },
]

const colDefsLocationGroups = [
  {
    field: "id",
    headerName: "Id",
    filter: 'agNumberColumnFilter',
    width: 200,
  },
  {
    field: "name",
    headerName: "Name",
    filter: 'agTextColumnFilter',
    width: 800,
  },

]

const defaultTab = {
  activeKey: 'sessions',
  tableName: 'vw_user_sessions',
  columnDefs: colDefUserSessions,
  db:'system',
};

function AdminDashboard(props) {
  const [newtablename, setNewTablename] = useState(defaultTab.tableName);
  const [newdbname, setNewDBname] = useState(defaultTab.db);
  const [columnData, setColumnData] = useState({})
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [isCreateOrUpdate, setIsCreateOrUpdate] = useState('create')
  const [activeTab, setActiveTab] = useState(defaultTab.activeKey)
  const [showUserPasswordModal, setShowUserPasswordModal] = useState(false)
  const handleUserPasswordModal = () => setShowUserPasswordModal(false);
  const [showAddLocationGroupModal, setShowAddLocationGroupModal] = useState(false)
  const handleShowAddLocationGroupModal = () => setShowAddLocationGroupModal(false);
  const [isModifyLocationGroup, setIsModifyLocationGroup] = useState(false);
  const [userPlans, setUserPlans] = useState();
  const [crawlCount, setCrawlCount] = useState();
  const [userCount, setUserCount] = useState();
  const [showCrawlInProgess, setShowCrawlInProgess] = useState(true);

  //===== methods for Ag-grid =====
  const gridRef = useRef();
  const getChildCount = useCallback(
    (data) => {
      return data ? data.childCount : undefined
    },
    []
  )

  const getContextMenuItems = (params) => {
    const { node } = params;
    var paymentResult = [
      {
        name: 'New',
        action: () => {
          handleAddNew(params)
        },
        icon: <i class="bi bi-plus-lg bi-lg"></i>,
      },
      {
        name: 'Modify',
        action: () => {
          handleModify(params)
        },
        icon: <i class="bi bi-pencil"></i>,
        disabled: !node && !node?.data
      },
      {
        name: 'Delete',
        action: () => {
          handleDelete(params)
        },
        icon: <i class="bi bi-trash3"></i>,
        disabled: !node && !node?.data
      },

    ]

    var userResult = [
      {
        name: 'Change Password',
        action: () => {
          handleChangePassword(params)
        },
        icon: <i class="bi bi-pencil"></i>,
        disabled: !node && !node?.data || node?.data.logintype != 'General'
      },
      {
        name: (node && node.data && (node.data.iskeywords === "Yes")) ? 'Disable Keywords' : 'Enable Keywords',
        action: () => {
          handleEnableKeywords(params)
        },
        icon: <i class="bi bi-x-circle"></i>,
        disabled: !node && !node?.data || node.data.id === props.loggedInUserId
      },
      {
        name: (node && node.data && (node.data.isformcampaigns === "Yes")) ? 'Disable Form Campaigns' : 'Enable Form Campaigns',
        action: () => {
          handleEnableFormCampaigns(params)
        },
        icon: <i class="bi bi-x-circle"></i>,
        disabled: !node && !node?.data || node.data.id === props.loggedInUserId
      },
      {
        name: (node && node.data && (node.data.isemailcampaigns === "Yes")) ? 'Disable Email Campaigns' : 'Enable Email Campaigns',
        action: () => {
          handleEnableEmailCampaigns(params)
        },
        icon: <i class="bi bi-x-circle"></i>,
        disabled: !node && !node?.data || node.data.id === props.loggedInUserId
      },
      {
        name: (node && node.data && (node.data.isactive === "Yes")) ? 'Deactivate' : 'Activate',
        action: () => {
          handleActivateStatus(params)
        },
        icon: <i class="bi bi-x-circle"></i>,
        disabled: !node && !node?.data || node.data.id === props.loggedInUserId
      },
      {
        name: 'Change User Plan',
        subMenu: userPlans ? userPlans.map((plan) => ({
          name: plan.name,
          action: () => handleChangeUserPlan(params, plan.id),
          disabled: node?.data?.plan === plan.name
        })) : [], // If userPlans is undefined, provide an empty array for subMenu
        icon: <i class="bi bi-x-circle"></i>,
        disabled: !node && !node?.data || node.data.id === props.loggedInUserId
      }
    ]

    var locationGroupResult = [
      {
        name: 'New',
        action: () => {
          handleAddNewLocationGroup(params)
        },
        icon: <i class="bi bi-plus-lg bi-lg"></i>,
      },
      {
        name: 'Modify',
        action: () => {
          handleModifyLocationGroup(params)
        },
        icon: <i class="bi bi-pencil"></i>,
        disabled: !node && !node?.data
      },
      {
        name: 'Delete',
        action: () => {
          handleDeleteLocationGroup(params)
        },
        icon: <i class="bi bi-trash3"></i>,
        disabled: !node && !node?.data
      },

    ]

    switch (activeTab) {
      case 'payments':
        return (paymentResult);
      case 'users':
        return (userResult);
      case 'locationgroups':
        return (locationGroupResult);
      default:
        return (params.defaultItems);
    }
  }

  const popupParent = useMemo(() => {
    return document.body;
  }, [])

  const autoGroupColumnDef = {
    headerName: 'Group',
    width: 500,
    cellRenderer: 'agGroupCellRenderer',
  };

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        }
      ],
      defaultToolPanel: '',
    };
  }, []);

  //===== methods for getting server data =====
  const datasource = {
    getRows: function (params) {
      const { request, tablename, db } = params;

      var tablenameset = tablename ? tablename : newtablename;
      tablenameset = (tablenameset === "vw_jobs") ? tablenameset.concat(" where (ownerid = userid) ") : tablenameset;

      const setdb = db ? db : 'system';
      fetch(serverApiUrl, {
        method: 'post',
        body: JSON.stringify({ ...request, tablenameset, setdb }),
        headers: { "Content-Type": "application/json; charset=utf-8" }
      })
        .then(httpResponse => httpResponse.json())
        .then(response => {
          params.successCallback(response.rows, response.lastRow);
        })
        .catch(error => {
          console.error(error);
          params.failCallback();
        });
    }
  };

  //===== Ag-grid configurations =====
  const gridOptions = {
    rowModelType: 'serverSide',
    columnDefs: defaultTab.columnDefs,

    defaultColDef: {
      editable: true,
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
    },

    sideBar: sideBar,
    rowGroupPanelShow: 'always',
    rowSelection: 'multiple',
    columnHoverHighlight: true,
    suppressRowGroupHidesColumns: true,
    autoGroupColumnDef: autoGroupColumnDef,
    getChildCount: getChildCount,
    popupParent: popupParent,
    cacheBlockSize: 50,

    // getContextMenuItems: getContextMenuItems,
    // getContextMenuItems: getContextMenuItems,
    // pivotMode: true,
    // debug: true,
    // maxBlocksInCache: 3,
    // purgeClosedRowNodes: true,
    // maxConcurrentDatasourceRequests: 2,
    // blockLoadDebounceMillis: 1000
  }

  const handleModify = (p) => {
    setShowPaymentModal(true)
    setIsCreateOrUpdate('update')
    setColumnData(p.node.data)
  }

  const handleDelete = (p) => {
    handleShow()
    setColumnData(p.node.data)
  }

  const handleAddNew = (p) => {
    setShowPaymentModal(true)
    setIsCreateOrUpdate('create')
  }

  const handleChangePassword = (p) => {
    setShowUserPasswordModal(true)
    setColumnData(p.node.data)
  }

  const handleActivateStatus = (p) => {
    const userid = p.node.data.id
    const currentStatus = p.node.data.isactive
    const request = {
      url: `/deactivateuser/${userid}`,
      method: "POST"
    }
    if (request) {
      axios(request).then(() => {
        onRefresh("vw_users", 'system')
      })
        .catch((err) => {
          console.log('error occured while deactivate user', err)
        })
    }

  }

  const handleEnableKeywords = (p) => {
    const userid = p.node.data.id
    const request = {
      url: `/toggleiskeywords/${userid}`,
      method: "POST"
    }
    if (request) {
      axios(request).then(() => {
        onRefresh("vw_users", 'system')
      })
        .catch((err) => {
          console.log('error occured while deactivate user', err)
        })
    }

  }

  const handleEnableFormCampaigns = (p) => {
    const userid = p.node.data.id
    const request = {
      url: `/toggleisformcampaigns/${userid}`,
      method: "POST"
    }
    if (request) {
      axios(request).then(() => {
        onRefresh("vw_users", 'system')
      })
        .catch((err) => {
          console.log('error occured while deactivate user', err)
        })
    }

  }
  const handleEnableEmailCampaigns = (p) => {
    const userid = p.node.data.id
    const request = {
      url: `/toggleisemailcampaigns/${userid}`,
      method: "POST"
    }
    if (request) {
      axios(request).then(() => {
        onRefresh("vw_users", 'system')
      })
        .catch((err) => {
          console.log('error occured while deactivate user', err)
        })
    }

  }
  

  const handleAddNewLocationGroup = (p) => {
    setShowAddLocationGroupModal(true)
    setIsModifyLocationGroup(false)
    
  }

  const handleModifyLocationGroup = (p) => {
    setShowAddLocationGroupModal(true)
    setColumnData(p.node.data)
    setIsModifyLocationGroup(true)
    
  }
  
  const handleDeleteLocationGroup = (p) => {
    const groupid = p.node.data.id
    const request = {
      url: `/deletelocationgroup/${groupid}`,
      method: "POST"
    }
    if (request) {
      axios(request).then(() => {
        onRefresh("locationgroups", 'system')
      })
        .catch((err) => {
          console.log('error occured while delete location', err)
        })
    }

  }

  const handleChangeUserPlan = (params, plan) => {
    const userid = params.node.data.id
    const request = {
      url: `/updateuserplan/${userid}/${plan}`,
      method: "POST"
    }
    if (request) {
      axios(request).then(() => {
        onRefresh("vw_users", 'system')
      })
        .catch((err) => {
          console.log('error occured while updating user plan', err)
        })
    }

  }
 
  const toggleCrawlComplete = () => {
    setShowCrawlInProgess(prevState => !prevState);
  };

  //===== Api call triggers =====
  const onGridReady = useCallback((params) => {
    gridOptions.api.setServerSideDatasource(datasource);
  }, []);

  const onRefresh = useCallback((tablename, db, showCrawlInProgess) => {
    const asyncRefresh = async () => {
    setNewTablename(tablename)
    setNewDBname(db)
    // Update the datasource with the new tablename
    const updatedDatasource = { ...datasource, getRows: (params) => datasource.getRows({ ...params, tablename, db }) };

    let updatedColumnDefs;

    switch (tablename) {
      case 'vw_countries':
        updatedColumnDefs = colDefsallCountries;
        break;
      case 'vw_postalcodes':
        updatedColumnDefs = colDefPostalCodes;
        break;
      case 'vw_user_sessions':
        updatedColumnDefs = colDefUserSessions;
        break;
      case 'vw_users':
        updatedColumnDefs = colDefUsers;
        break;
      case 'vw_payments':
        updatedColumnDefs = colDefsPayments;
        break;
      case 'vw_usercredits':
        updatedColumnDefs = colDefsCredits;
        break;
      case 'vw_jobs':
        updatedColumnDefs = colDefsActivities;
        break;
      case 'locationgroups':
        updatedColumnDefs = colDefsLocationGroups;
        break;
      default:
        updatedColumnDefs = defaultTab.tableName;


    }
    gridRef.current.api.setColumnDefs([]);
    gridRef.current.api.setColumnDefs(updatedColumnDefs);
    
    if (tablename === 'vw_jobs' && showCrawlInProgess) {
      const response = await axios.get(`/activecrawl`);
      if(response.data[0].count > 0){
        const filterModel = {
          status_message: {
            type: 'contains',
            filter: 'In Progress'
          }
        };
        gridRef.current.api.setFilterModel(filterModel);
      }
      
    }
    gridRef.current.api.setServerSideDatasource(updatedDatasource);
  }
  asyncRefresh()
  }, []);

  const tabRefresh = () => {
    gridRef.current.api.refreshServerSide({purge: true})
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteSuccess = (e) => {

    e.preventDefault();
    e.stopPropagation();
    const upId = columnData.id;
    const request = {
      url: `/deletePayment/${upId}`,
      method: "PUT"
    }
    if (request) {
      axios(request).then(() => {
        onRefresh("vw_payments", 'system')
        setShow(false)
      })
        .catch((err) => {
          console.log('error occured while updating payment', err)
          alert('Server Error! unable to update payment')
        })
    }
  }

  useEffect(() => {
    const fetchUserPlans = async () => {
      try {
        const response = await axios.get('/userplans');
        if (!response.status === 200) {
          throw new Error('Failed to fetch user plans');
        }
        const data = await response.data;
        setUserPlans(data); 
      } catch (error) {
        console.error('Error fetching user plans:', error);
      }
    };

    if (activeTab === 'users') {
      fetchUserPlans();
    }
    if(activeTab === 'activities'){
      onRefresh("vw_jobs", 'leads', showCrawlInProgess);
    }
    
  }, [activeTab, showCrawlInProgess]); 

  const onRefreshLocationGroups = () => {
    onRefresh("locationgroups", 'system')
  };

  useEffect( () =>{
    async function fetchData(){
      try{
        const response = await axios.get('/countofinprogressjobs', {})
        const userResponse = await axios.get('/countofloggedinusers', {})
        setCrawlCount(response.data[0].count)
        setUserCount(userResponse.data[0].count)
        }catch(error){
        }
    }
    fetchData()
  },[]) 
  
  return (
    <div className='container-fluid'>
      <Tab.Container defaultActiveKey={defaultTab.activeKey} onSelect={setActiveTab}>
        <Nav variant='tabs' className='fs-6 border' >
          <Nav.Item>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip-sessions">There are {userCount} active user sessions</Tooltip>}
            >
              <Nav.Link
                 eventKey="sessions"
                 id="vw_user_sessions"
                 onClick={() => onRefresh('vw_user_sessions', 'system')}
              >
                Sessions ({userCount})
              </Nav.Link>
            </OverlayTrigger>
          </Nav.Item>
          <Nav.Item><Nav.Link eventKey="users" id="vw_users" onClick={() => onRefresh('vw_users', 'system')}>Users</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link eventKey="postalcodes" id="vw_postalcodes" onClick={() => onRefresh("vw_postalcodes", 'system')}>Postal Codes</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link eventKey="locationgroups" id="locationgroups" onClick={() => onRefresh("locationgroups", 'system')}>Location Groups</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link eventKey="countries" id="vw_countries" onClick={() => onRefresh("vw_countries", 'system')}>Countries</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link eventKey="payments" id="vw_payments" onClick={() => onRefresh("vw_payments", 'system')}>Payments</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link eventKey="credits" id="vw_usercredits" onClick={() => onRefresh("vw_usercredits", 'system')}>Credits</Nav.Link></Nav.Item>
          <Nav.Item>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip-sessions">There are {crawlCount} active crawl processes</Tooltip>}
            >
              <Nav.Link eventKey="activities" id="vw_jobs" onClick={() => onRefresh("vw_jobs", 'leads')}>
                Activities ({crawlCount})
              </Nav.Link>
            </OverlayTrigger>
          </Nav.Item>
          <Nav.Item className='ms-auto'>
            {
              newtablename == "vw_payments" ? 
              (
                <>
                  <Button title="New" className={`btn btn-primary mx-1 float-right`} onClick={() => { setShowPaymentModal(true); setIsCreateOrUpdate('create') }}><i className={`bi bi-plus-lg bi-lg`} ></i></Button>
                </>
              ): ''
            }

            <Button title="All / Crawl In Progress" className={`btn btn-primary mx-1 float-right ${activeTab === 'activities' && crawlCount > 0 ? 'visible' : 'd-none'}`} onClick={() => toggleCrawlComplete()}><i class={`bi ${showCrawlInProgess ? 'bi-circle-half' : 'bi-check-circle-fill'} `}></i></Button>

            <Button title="Refresh" className={`btn btn-primary mx-1 float-right`} onClick={tabRefresh}><i className={`bi bi-arrow-repeat`}></i></Button>
          </Nav.Item>
        </Nav>
        <Tab.Content className='w-100' style={{ height: "calc(100% - 34px)" }} animation={true} mountOnEnter={true} unmountOnExit={true} >
          <div className="ag-theme-alpine" style={{ width: '100%', height: '84vh' }}>
            <AgGridReact
              ref={gridRef}
              gridOptions={gridOptions}
              onGridReady={onGridReady}
              getContextMenuItems={(params) => getContextMenuItems(params)}
            />
          </div>
        </Tab.Content>
      </Tab.Container>
      <AdminPaymentForm showPaymentModal={showPaymentModal} setShowPaymentModal={setShowPaymentModal} onRefresh={onRefresh} columnData={columnData} isCreateOrUpdate={isCreateOrUpdate} setIsCreateOrUpdate={setIsCreateOrUpdate} loggedInUserId={props.loggedInUserId} />
      <Modal show={show} onHide={handleClose} animation={false} >
        <span className='d-flex justify-content-center' style={{ fontSize: "30px", marginTop: "10px", color: "red" }}><i class="bi bi-exclamation-triangle-fill"></i></span>
        <h3 className='d-flex justify-content-center' style={{ margin: "10px 0 0 10px", fontWeight: "600" }}>Delete Payment</h3>
        <Modal.Body className='d-flex justify-content-center' style={{ fontSize: "16px" }}>Are you sure you won't revert this?</Modal.Body>
        <div className='d-flex justify-content-center p-2'>
          <Button style={{ padding: "10px 20px 10px 20px", marginRight: "10px", color: "black", backgroundColor: "#F2F1EB", border: "none", fontSize: "17px", fontWeight: "600" }} onClick={handleClose}>
            Cancel
          </Button>
          <Button style={{ padding: "10px 20px 10px 20px", backgroundColor: "red", border: "none", fontSize: "17px", fontWeight: "600" }} onClick={handleDeleteSuccess}>
            Delete
          </Button>
        </div>
      </Modal>

      <div>
        <Modal show={showUserPasswordModal} onHide={handleUserPasswordModal} animation={false} centered>

          <Modal.Body>
            <ChangeUserPassword email={columnData.email} setShowUserPasswordModal={setShowUserPasswordModal} />
          </Modal.Body>
        </Modal>
      </div>

      <div>
        <Modal show={showAddLocationGroupModal} onHide={handleShowAddLocationGroupModal} animation={false} centered size='xl'>
          <Modal.Header closeButton={true} >
            <div className="fs-6"><strong> {isModifyLocationGroup ? `Update New Location Group` : `Create New Location Group` } </strong></div>
          </Modal.Header>
          <Modal.Body className="m-1 p-0">
            <AGgridListbox setShowAddLocationGroupModal={setShowAddLocationGroupModal} locationRowData={columnData} isModifyLocationGroup={isModifyLocationGroup} onSuccess={onRefreshLocationGroups}/>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default AdminDashboard;