import React, { useState, useEffect, useRef } from 'react';
import {Button, Card, Tab, Nav, Form, Modal, OverlayTrigger, Tooltip, Dropdown} from 'react-bootstrap' 

import SplitPane from 'split-pane-react';
import axios from 'axios';
import 'split-pane-react/esm/themes/default.css';

import CustomCrawl from "./customcrawl";
import Jobs from "./jobs";
import Leads from "./leads";
import LeadDetails from "./leaddetails";
import LeadTabs from "./leadtabs";
import LeadFilters from "./leadfilters";
import ShareObject from './sharejob';
import UserCategories from './usercategories';

const CATALOG_TAB = 'catalog';
const LISTS_TAB = 'lists';
const LEADS_TAB = 'leads';
const USER_CATEGORIES_TAB = 'usercategories';

function Main(props) {
  const SIDE_MIN_WIDTH = '40px';
  const SIDE_MAX_WIDTH = '240px';

  const catalogRef = useRef(null);
  const jobsRef = useRef(null);
  const leadsRef = useRef(null);
  const leadFiltersRef = useRef(null);
  const ucgRef = useRef(null);
  const leadDetailsRef = useRef(null);
  const [leadDetailsWidth, setLeadDetailsWidth] = useState(0);

  const [formsDetails, setFormsDetails] = useState({})
  const [templatesLoading, setTemplatesLoading] = useState(false)
  const [campaignMode, setCampaignMode] = useState(2)
  const [selectedTemplate, setSelectedTemplate] = useState(0)
  const [showStartFormCampaignModal, setShowStartCampaign] = useState(false)
  const [formtemplates, setFormTemplates] = useState([])
  const [sbsplit, setSbsplit] = useState([SIDE_MIN_WIDTH, 'auto']);
  const [hsplit, setHsplit] = useState(["50%", 'auto']);
  const [vsplit, setVsplit] = useState(["60%", 'auto']);
  const thisResizerSize = 5;
  const [isShareJobsModalOpen, setIsShareJobsModalOpen] = useState(false);

  const [thisProps] = useState(props);

  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => { 
    setShowSidebar(!showSidebar); 
  };

  useEffect(() => {
    setSbsplit(showSidebar ? [SIDE_MAX_WIDTH, 'auto'] : [SIDE_MIN_WIDTH, 'auto']);
  }, [showSidebar]);

  const [leadData, setLeadData] = useState([]);
  const updateLeadData = (data) => { setLeadData(data) };

  const [leadsFilter, setLeadsFilter] = useState({});
  const updateLeadsFilter = (data) => { setLeadsFilter(data) };

  const [barChartData, setBarChartData] = useState({});
  const updateBarChartData = (data) => { setBarChartData(data) };

  const [sbarChartData, setSBarChartData] = useState({});
  const updateSBarChartData = (data) => { setSBarChartData(data) };

  const [pieChartData, setPieChartData] = useState({});
  const updatePieChartData = (data) => { setPieChartData(data) };

  const [categoryData, setCategoryData] = useState({});
  const updateCategoryData = (data) => { setCategoryData(data)};
  const [updatingCategoryData, setUpdatingCategoryData] = useState(false);
  const passUpdatingCategoryData = (data) => { setUpdatingCategoryData (data) };

  const [selectedCategoryData, setSelectedCategoryData] = useState([]);
  const passSelectedCategoryData = (data) => { setSelectedCategoryData(data) };
  const clearSelectedCategoryData = () => { leadFiltersRef.current.clearSelectedCategories() };

  const [selectedKeywords, setSelectedKeywords] = useState('');
  const passSelectedKeywords = (data) => { setSelectedKeywords(data) };
  const clearSelectedKeywords = () => { leadFiltersRef.current.clearSelectedKeywords() };

  const [leadResultsCount, setLeadResultsCount] = useState(0);
  const passLeadResultsCount = (data) => { setLeadResultsCount(data) };

  const [isCrawling, setIsCrawling] = useState(false);
  const passIsCrawling = (data) => { 
    setIsCrawling(data); 
    setResumeCrawl(false); 
    setPauseCrawl(false);
    setAbortCrawl(false);
  };

  const [statusUpdate, setStatusUpdate] = useState('');
  const passStatusUpdate = (data) => (setStatusUpdate(data));

  const [placeProgress, setPlaceProgress] = useState(0);
  const passPlaceProgress = (data) => (setPlaceProgress(data));

  const [placesRemaining, setPlacesRemaining] = useState(0);
  const passPlacesRemaining = (data) => (setPlacesRemaining(data));

  const [leadCount, setLeadCount] = useState(0);
  const passLeadCount = (data) => (setLeadCount(data));

  const [leadProgress, setLeadProgress] = useState(0);
  const passLeadProgress = (data) => (setLeadProgress(data));

  const [leadStatus, setLeadStatus] = useState(0);
  const passLeadStatus = (data) => (setLeadStatus(data));

  const [forceRefresh, setForceRefresh] = useState(false);
  const passForceRefresh = () => (setForceRefresh(!forceRefresh));

  const [forceCatalogRefresh, setForceCatalogRefresh] = useState(false);
  const passForceCatalogRefresh = () => (setForceCatalogRefresh(!forceCatalogRefresh));

  const [forceJobsRefresh, setForceJobsRefresh] = useState(false);
  const passForceJobsRefresh = () => (setForceJobsRefresh(!forceJobsRefresh));

  const [forceUCGRefresh, setForceUCGRefresh] = useState(false);
  const passForceUCGRefresh = () => (setForceUCGRefresh(!forceUCGRefresh));

  const passStartNewJob = () => {jobsRef.current.startNewJob()};

  const [jobCount, setJobCount] = useState(0);
  const passJobCount = (data) => (setJobCount(data));

  const [jobStatus, setJobStatus] = useState(0);
  const passJobStatus = (data) => (setJobStatus(data));

  const [activeJobId, setActiveJobId] = useState(0);
  const passActiveJobId = (data) => { setActiveJobId(data) };

  const [updatingCatalogData, setUpdatingCatalogData] = useState(false);
  const passUpdatingCatalogData = (data) => (setUpdatingCatalogData(data));

  const [updatingJobsData, setUpdatingJobsData] = useState(false);
  const passUpdatingJobsData = (data) => (setUpdatingJobsData(data));

  const [updatingLeadsData, setUpdatingLeadsData] = useState(false);
  const passUpdatingLeadsData = (data) => (setUpdatingLeadsData(data));

  const [updatingUCGData, setUpdatingUCGData] = useState(false);
  const passUpdatingUCGData = (data) => (setUpdatingUCGData(data));

  const [selectedUCGId, setSelectedUCGId] = useState(0);
  const passSelectedUCGId = (data) => { setSelectedUCGId(data) };

  const [selectedJobId, setSelectedJobId] = useState(0);
  const [selectedJobName, setSelectedJobName] = useState(0);
  const [selectedJobOwner, setSelectedJobOwner] = useState(false);
  const passSelectedJobId = (data) => { setSelectedJobId(data) };
  const passSelectedJobName = (data) => { setSelectedJobName(data) };
  const passSelectedJobOwner = (data) => { setSelectedJobOwner(data) };
  const pushSelectedJobId = (data) => {
    setSelectedJobId(data);
    jobsRef.current.setSelectedJob(data);
  };

  const [resumeCrawl, setResumeCrawl] = useState(false);
  const passResumeCrawl = (data) => { setResumeCrawl(data) };
  const [pauseCrawl, setPauseCrawl] = useState(false);
  const passPauseCrawl = (data) => { setPauseCrawl(data) };
  const [abortCrawl, setAbortCrawl] = useState(false);
  const passAbortCrawl = (data) => { setAbortCrawl(data) };

  const [showGridView, setShowGridView] = useState(true);
  const [showCatalogGridView, setShowCatalogGridView] = useState(true);
  const [showJobsGridView, setShowJobsGridView] = useState(true);
  const [showUCGGridView, setShowUCGGridView] = useState(true);

  const [showCrawlComplete, setShowCrawlComplete] = useState(true);

  const [activeTab, setActiveTab] = useState(sessionStorage.getItem('mainActiveTab') || CATALOG_TAB);
  const [isCatalogView, setIsCatalogView] = useState(true);
  const [isJobsView, setIsJobsView] = useState(false);
  const [isLeadsView, setIsLeadsView] = useState(false);
  const [isUCGView, setIsUCGView] = useState(false);

  const [exportingLeads, setExportingLeads] = useState(false);
  const passExportingLeads = (data) => { setExportingLeads(data) };

  const [unlockAllLeadsBtnEnabled,setUnlockAllLeadsBtnEnabled]=useState(false);
  const [showStartEmailCampaignModal,setShowStartEmailCampaignModal]=useState(false)
  const [emailtemplates,setEmailTemplates]=useState([])
  const [selectedEmailTemplateId,setSelectedTemplateId]=useState(-1)
  

  const [elapsedTime, setElapsedTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const passStartTime = (data) => {
    setStartTime(data);
    if (data > 0) { setElapsedTime(0) };
  };

  const handleTemplateSelection = event => {
    setSelectedTemplate(event.target.value)
    console.log(event.target.value)
  }

  const handleCampaignCheckbox = (event) => {
    if(event.target.checked) setCampaignMode(1)
    else setCampaignMode(2)
}

  const handleTabSelection = (key) => {
    setActiveTab(key);
    if (key !== LEADS_TAB) {
      sessionStorage.setItem('mainActiveTab', key);
    }
    setShowSidebar((key === LISTS_TAB) || (key === LEADS_TAB)); 
    setIsCatalogView(key === CATALOG_TAB);
    setIsJobsView(key === LISTS_TAB);
    setIsLeadsView(key === LEADS_TAB);
    setIsUCGView(key === USER_CATEGORIES_TAB);
  };

  const tabRefresh = () => {
    if (activeTab === CATALOG_TAB) {
      setForceCatalogRefresh(!forceCatalogRefresh)
    } else  if (activeTab === LISTS_TAB) {
      setForceJobsRefresh(!forceJobsRefresh)
    } else if (activeTab === LEADS_TAB) {
      setForceRefresh(!forceRefresh);
      setSelectedCategoryData([]);
      setSelectedKeywords('');
      leadsRef.current.fetchCategoryData(selectedJobId);
    } else if (activeTab === USER_CATEGORIES_TAB) {
      setForceUCGRefresh(!forceUCGRefresh)
    }
  }

  const toggleView = () => {
    setShowGridView(!showGridView)
  }

  const toggleCatalogView = () => {
    setShowCatalogGridView(!showCatalogGridView)
  }

  const toggleJobsView = () => {
    setShowJobsGridView(!showJobsGridView)
  }

  const toggleUCGView = () => {
    setShowUCGGridView(!showUCGGridView)
  }

  const toggleCrawlComplete = () => {
    setLeadsFilter({index: 16, value: !showCrawlComplete})
    setShowCrawlComplete(!showCrawlComplete)
  }

  const switchToList = () => {
    handleTabSelection('leads');
  }

  useEffect(() => {

    getTemplates()

    if (!leadDetailsRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setLeadDetailsWidth(leadDetailsRef.current.offsetWidth);
    });
    resizeObserver.observe(leadDetailsRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    let interval;

    if (startTime > 0) {
      interval = setInterval(() => {
        setElapsedTime(elapsedTime + 1)
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [startTime, elapsedTime])

  useEffect(() => {
    if ((activeTab === CATALOG_TAB) && (!updatingCatalogData)) {
      catalogRef.current.fetchData();
    }
  }, [forceCatalogRefresh]);

  useEffect(() => {
    if ((activeTab === LISTS_TAB) && (!updatingJobsData)) {
      jobsRef.current.fetchData();
    }
  }, [forceJobsRefresh]);

  useEffect(() => {
    if ((activeTab === USER_CATEGORIES_TAB) && (!updatingUCGData)) {
      ucgRef.current.fetchData();
    }
  }, [forceUCGRefresh]);

  useEffect(() => {
    if ((activeTab === LEADS_TAB) && (!updatingLeadsData)) {
      leadsRef.current.fetchData(selectedJobId, false, true);
    }
  }, [selectedJobId, activeTab]);

  useEffect(() => {
    if ((activeTab === LEADS_TAB) && (!updatingLeadsData)) {
      leadsRef.current.fetchData(selectedJobId, false, false);
    }
  }, [forceRefresh, selectedCategoryData, selectedKeywords]);

  useEffect(() => {
    if ((activeTab === LEADS_TAB) && (!updatingCategoryData)) {
      leadFiltersRef.current.refreshCategoryData();
    }
  }, [categoryData]);

  useEffect(() => {
    leadsRef.current.toggleView(!showGridView);
  }, [showGridView]);

  useEffect(() => {
    catalogRef.current.toggleView(!showCatalogGridView);
  }, [showCatalogGridView]);

  useEffect(() => {
    jobsRef.current.toggleView(!showJobsGridView);
  }, [showJobsGridView]);

  useEffect(() => {
    ucgRef.current.toggleView(!showUCGGridView);
  }, [showUCGGridView]);

  useEffect(() => {
    leadsRef.current.applyFilter(leadsFilter)
  }, [leadsFilter]);

  useEffect(() => {
    tabRefresh()
  }, [activeTab])


  useEffect(() => {

    (async () => {
      if (showStartFormCampaignModal)
    try {

      setFormsDetails({});
      setTemplatesLoading(true);

      const filters = { jid: selectedJobId, selPlanId: thisProps.plid, selKeywords: selectedKeywords, selCategories: leadsRef.current.getWhereSelectedCategories(), agRequest: leadsRef.current.agRequestRef.current }
      const [templatesResponse, formcount] = await Promise.all([
        axios.get(`/getformtemplates/${thisProps.uid}`),
        axios.post( `/getformcounts`,filters )
      ]);
      
      setFormTemplates(templatesResponse.data);
      setFormsDetails(formcount.data || {}); 

    } catch (error) {
      alert("Error :"+ error.message);
    } finally{
      setTemplatesLoading(false);
    }
  })();

  }, [showStartFormCampaignModal]);

  const newUCG = () => {
    ucgRef.current.setCategoryModalNew(true);
    ucgRef.current.openCategoryModal();
  }

  const openShareJobModal = () => {
    setIsShareJobsModalOpen(true);
  };

  const closeShareJobModal = () => {
    setIsShareJobsModalOpen(false);
  };

  const [showFileNameModal,setShowFileNameModal]=useState(false)
  const [fileName,setFileName]=useState(null)

  const exportList = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === true) 
    {
      setShowFileNameModal(false)
      if (fileName) {
        setExportingLeads(true);
        leadsRef.current.exportLeads(selectedJobId, fileName);
        /*
        fetch(`/api/exportleads/${selectedJobId}/${fileName}`)
          .then(response => {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = `${fileName}.xlsx`;
              a.click();
              a.remove()
            });
          });
        */
      }
    } 
  }

  const onStartEmailCampaignClicked=()=>{
    setShowStartEmailCampaignModal(true)

  }

  async function createEmailCampaign(e){

    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === true) 
    {
      setShowStartEmailCampaignModal(false)
      leadsRef.current.createEmailCampaign(selectedEmailTemplateId,selectedJobId,props.uid)
    }
}

const getTemplates = () => {
  axios.get(`/emailtemplates/userid/${props.uid}`)
      .then(res => {
          setEmailTemplates(res.data)
      })
      .catch(err => {
          console.log(err)
      })
}

const startFormCampaign = async event => {
  if (selectedTemplate > 0) {

        setTemplatesLoading(true);
        try {
              await leadsRef.current.createFormCampaign(selectedTemplate, selectedJobId, campaignMode, thisProps.uid) 
              alert(`Campaign Started!`);
        } catch (err) {
              console.log(err);
              alert("Unable to Start Campaign. Server Error!!!");
        } finally {
              setShowStartCampaign(false);
              setTemplatesLoading(false);
              setSelectedTemplate(0)
        }

  } else {
    alert("You need to select a list to start a campaign");
  }
};

  return (
    <React.Fragment>
      <SplitPane allowResize={false} sizes={sbsplit} onChange={setSbsplit}>
        <div className='w-100 border shadow'>
          <div className='w-100 p-2 d-flex align-items-center bg-primary text-white shadow' style={{ height: '40px' }}>
            <span className={`w-100 fs-5 fw-bold`}>{`${showSidebar ? 'Select' : ''}`}</span>
            <Button size="lg" className={`m-0 p-0 border border-primary ${(isJobsView || isLeadsView) ? 'd-block' : 'd-none'}`} onClick={toggleSidebar}>
              <i className={`bi ${showSidebar ? 'bi-caret-left-fill' : 'bi-caret-right-fill'}`}></i>
            </Button>
          </div>
          <div className={`w-100 p-2 side-panel overflow-auto ${showSidebar ? 'visible' : 'invisible'}`}>
            {(activeTab === LISTS_TAB) ? (
              <CustomCrawl uid={thisProps.uid} plid={thisProps.plid} plan={thisProps.plan} ajid={activeJobId} jid={selectedJobId} jstatus={jobStatus} jcount={jobCount} resumeCrawl={resumeCrawl} pauseCrawl={pauseCrawl} abortCrawl={abortCrawl} icu={passIsCrawling} ppu={passPlaceProgress} pru={passPlacesRemaining} lcu={passLeadCount} lpu={passLeadProgress} lsu={passLeadStatus} stu={passStatusUpdate} pfjr={passForceJobsRefresh} pfr={passForceRefresh} psnj={passStartNewJob} aju={passActiveJobId} sju={pushSelectedJobId} pst={passStartTime} />
            ) : ''}
            {(activeTab === LEADS_TAB) ? (
              <LeadFilters ref={leadFiltersRef} jid={selectedJobId} jname={selectedJobName} jowner={selectedJobOwner} catdata={categoryData} ucd={updatingCategoryData} uld={updatingLeadsData} uscd={passSelectedCategoryData} ukwd={passSelectedKeywords} ssjn={passSelectedJobName} />
            ) : ''}
          </div>
        </div>
        <div className='w-100 h-100 d-flex flex-column overflow-hidden'>
          <Tab.Container defaultActiveKey={CATALOG_TAB} onSelect={handleTabSelection} activeKey={activeTab}>
            <Nav variant='tabs' className='fs-6 border' >
              <Nav.Item><Nav.Link eventKey={CATALOG_TAB}>Catalog{updatingCatalogData ? (<React.Fragment>&nbsp;<i className="fa fa-spinner fa-pulse text-white"></i></React.Fragment>) : ''}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey={LISTS_TAB} >My Lists{updatingJobsData ? (<React.Fragment>&nbsp;<i className="fa fa-spinner fa-pulse text-white"></i></React.Fragment>) : ''}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey={LEADS_TAB}>{`Leads ${(selectedJobId > 0) ? `[List #${thisProps.uid}00${selectedJobId}]` : ''}`}{updatingLeadsData ? (<React.Fragment>&nbsp;<i className="fa fa-spinner fa-pulse text-white"></i></React.Fragment>) : ''}</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey={USER_CATEGORIES_TAB}>My Categories{updatingUCGData ? (<React.Fragment>&nbsp;<i className="fa fa-spinner fa-pulse text-white"></i></React.Fragment>) : ''}</Nav.Link></Nav.Item>
              <Nav.Item className='ms-auto d-flex'>
                {isCatalogView ? (<>
                  <Button title="Grid / Cards" className={`btn btn-primary mx-1 float-right`} onClick={toggleCatalogView}><i className={`bi ${showCatalogGridView ? 'bi-list' : 'bi-grid'}`}></i></Button>
                </>) : ''}
                {isJobsView ? (<>
                  <Button title="Grid / Cards" className={`btn btn-primary mx-1 float-right`} onClick={toggleJobsView}><i className={`bi ${showJobsGridView ? 'bi-list' : 'bi-grid'}`}></i></Button>
                </>) : ''}
                {isLeadsView ? (<>
                  {(leadResultsCount > 0) ? (
                  <Button className={`mx-1 float-right`} variant="outline-primary">{updatingLeadsData ? (<React.Fragment>&nbsp;<i className="fa fa-spinner fa-pulse"></i></React.Fragment>) : `Count: ${leadResultsCount}`}</Button>
                  ) : ''}
                  <Button title="All / Scan Completed" className={`btn btn-primary mx-1 float-right`} disabled={updatingLeadsData} onClick={toggleCrawlComplete}><i class={`bi ${showCrawlComplete ? 'bi-check-circle-fill' : 'bi-circle-half'}`}></i></Button>
                  <Button title="Grid / Cards" className={`btn btn-primary mx-1 float-right`} onClick={toggleView}><i className={`bi ${showGridView ? 'bi-list' : 'bi-grid'}`}></i></Button>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="campaign-dropdown"><i class={`bi bi-arrow-right-square`}></i></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Button title="Start Email Campaign" className={`btn btn-primary mx-1 float-right`} onClick={onStartEmailCampaignClicked}>
                            {/* <i class={`bi bi-arrow-right-square`}></i> */}
                            Start Email Campaign
                          </Button>
                        </Dropdown.Item>
                        <Dropdown.Item>
										<Button
                      style={{pointerEvents:'auto'}}
											title={[2, 3].includes(jobStatus)? "Start Campaign": "Campaign cannot be started for Running or Paused Lists"}
											className={`btn btn-primary mx-1 float-right`}
                      disabled={![2, 3].includes(jobStatus)}
											onClick={() => {
												setTemplatesLoading(true);
												setShowStartCampaign(true);
											}}>
										      	{/* <i className={`bi bi-mailbox`}></i> */}
                            Start Form Campaign
										</Button>
                        </Dropdown.Item> 
                       
                    </Dropdown.Menu>
                  </Dropdown>	

                  {selectedJobOwner ? (
                  <Button title="Share List" className={`btn btn-primary mx-1 float-right`} onClick={openShareJobModal}><i className={`bi bi-share`}></i></Button>
                  ) : ''}
                  <Button title="Unlock All" className={`btn btn-primary mx-1 float-right`} disabled={updatingLeadsData || (!unlockAllLeadsBtnEnabled)} onClick={()=>leadsRef.current.unlockAllClicked(selectedJobId)}><i className={`bi bi-unlock`}></i></Button>
                  <Button title="Excel Export" className={`btn btn-primary mx-1 float-right`} disabled={updatingLeadsData || exportingLeads} onClick={()=>setShowFileNameModal(true)} ><i className={`${exportingLeads ? 'fa fa-spinner fa-pulse' : 'bi bi-download'}`}></i></Button>
                </>) : ''}
                {isUCGView ? (<>
                  <Button title="New Category Group" className={`btn btn-primary mx-1 float-right`} onClick={newUCG}><i class={`bi bi-plus text-white`} style={{fontWeight:'bold'}}></i></Button>
                  <Button title="Grid / Cards" className={`btn btn-primary mx-1 float-right`} onClick={toggleUCGView}><i className={`bi ${showUCGGridView ? 'bi-list' : 'bi-grid'}`}></i></Button>
                </>) : ''}
                <Button title="Refresh" className={`btn btn-primary mx-1 float-right`} disabled={updatingCatalogData || updatingJobsData || updatingLeadsData || updatingUCGData} onClick={tabRefresh}><i className={`bi bi-arrow-repeat`}></i></Button>
              </Nav.Item>
              {isLeadsView ? (
              <Nav.Item><div style={{width: `${leadDetailsWidth}px`}}></div></Nav.Item>
              ) : ''}
            </Nav>
            <Tab.Content className='w-100' style={{ height: "calc(100% - 34px)" }} animation="true" mountOnEnter={true} unmountOnExit={true} >
              <Tab.Pane className='w-100 h-100' eventKey={CATALOG_TAB} title="Catalog">
                <Jobs ref={catalogRef} catalog={true} uid={thisProps.uid} ajid={activeJobId} ajpp={placeProgress} ajpr={placesRemaining} ajlu={leadCount} ajlp={leadProgress} ajls={leadStatus} ajsp={statusUpdate} aju={passActiveJobId} sju={passSelectedJobId} ssjn={passSelectedJobName} ujs={passJobStatus} ujo={passSelectedJobOwner} ujc={passJobCount} stl={switchToList} ujd={passUpdatingCatalogData} urc={passResumeCrawl} upc={passPauseCrawl} uac={passAbortCrawl} planId={thisProps.plid} setUnlockAllLeadsBtnEnabled={setUnlockAllLeadsBtnEnabled} setFileName={setFileName} />
              </Tab.Pane>
              <Tab.Pane className='w-100 h-100' eventKey={LISTS_TAB} title="My Lists">
                <Jobs ref={jobsRef} catalog={false} uid={thisProps.uid} ajid={activeJobId} ajpp={placeProgress} ajpr={placesRemaining} ajlu={leadCount} ajlp={leadProgress} ajls={leadStatus} ajsp={statusUpdate} aju={passActiveJobId} sju={passSelectedJobId} ssjn={passSelectedJobName} ujs={passJobStatus} ujo={passSelectedJobOwner} ujc={passJobCount} stl={switchToList} ujd={passUpdatingJobsData} urc={passResumeCrawl} upc={passPauseCrawl} uac={passAbortCrawl} planId={thisProps.plid} setUnlockAllLeadsBtnEnabled={setUnlockAllLeadsBtnEnabled} setFileName={setFileName} />
              </Tab.Pane>
              <Tab.Pane className='w-100 h-100' eventKey={LEADS_TAB} title="Leads">
                <SplitPane split="horizontal" sizes={hsplit} resizerSize={thisResizerSize} onChange={setHsplit}>
                  <SplitPane sizes={vsplit} resizerSize={thisResizerSize} onChange={setVsplit}>
                    <Card className={`w-100 h-100 overflow-auto`}>
                      <Leads ref={leadsRef} jid={selectedJobId} planId={thisProps.plid} data={updateLeadData} bardata={updateBarChartData} sbardata={updateSBarChartData} piedata={updatePieChartData} catdata={updateCategoryData} selcatdata={selectedCategoryData} selkwdata={selectedKeywords} isCrawling={isCrawling} ucd={passUpdatingCategoryData} uld={passUpdatingLeadsData} lrcu={passLeadResultsCount} lexu={passExportingLeads} loggedInUserId={thisProps.uid} crawlComplete={showCrawlComplete} cscd={clearSelectedCategoryData} ckwd={clearSelectedKeywords} />
                    </Card>
                    <Card ref={leadDetailsRef} className="w-100 h-100 overflow-auto">
                      <LeadDetails key={selectedJobId} data={leadData} view={LEADS_TAB} />
                    </Card>
                  </SplitPane>
                  <Card className="w-100 h-100 overflow-auto">
                    <LeadTabs key={selectedJobId} jid={selectedJobId} data={leadData} view={LEADS_TAB} bardata={barChartData} sbardata={sbarChartData} piedata={pieChartData} lfu={updateLeadsFilter} ajlu={leadCount} ajid={activeJobId} activeTab={activeTab} />
                  </Card>
                </SplitPane>
              </Tab.Pane>
              <Tab.Pane className='w-100 h-100' eventKey={USER_CATEGORIES_TAB} title="My Categories">
                <UserCategories ref={ucgRef} uid={thisProps.uid} uucgd={passUpdatingUCGData} sucgu={passSelectedUCGId} pfr={passForceUCGRefresh} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </SplitPane>
      {isLeadsView ? (<>
      <ShareObject isOpen={isShareJobsModalOpen} onClose={closeShareJobModal} objType="job" objId={selectedJobId} loggedInUserId={thisProps.uid} />
      <Modal show={showFileNameModal} centered>
        <Modal.Body className='p-2 shadow'>
          <Form noValidate validated onSubmit={exportList}>
            <div className='d-flex flex-column'>
              <Form.Group className="m-3" controlId="filename">
                <Form.Label className='fs-6 fw-bold'>Give name to the file</Form.Label>
                <Form.Control
                  name="filename"
                  type='text'
                  required
                  defaultValue={selectedJobName}
                  placeholder='File Name'
                  autoFocus
                  pattern="^[a-zA-Z0-9_\- ]{1,38}$"
                  onChange={(e)=>setFileName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid"> Filename should not contain special characters eg. @#?!/</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid"> Filename should not more than 38 characters</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='p-2 d-flex justify-content-end'>
              <Button className='mx-1' variant="secondary" onClick={()=>setShowFileNameModal(false)}>
                Cancel
              </Button>
              <Button type="submit" className={`mx-1 ${(fileName === '') ? 'disabled' : ''}`} variant="primary" >
                Ok
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showStartEmailCampaignModal} centered>
        <Modal.Body className='p-2 shadow'>
          <Form noValidate validated onSubmit={createEmailCampaign}>
            <div className='d-flex flex-column'>
              <Form.Group className="m-3" controlId="emailtemplate">
                <Form.Label className='fs-6 fw-bold'>Email Template</Form.Label>
                {/* <Form.Control
                  name="filename"
                  type='text'
                  required
                  defaultValue={selectedJobName}
                  placeholder='File Name'
                  autoFocus
                  pattern="^[a-zA-Z0-9_\- ]{1,38}$"
                  onChange={(e)=>setFileName(e.target.value)}
                /> */}
                    <Form.Select onChange={(e)=>{setSelectedTemplateId(e.target.value)}} size="sm" value={selectedEmailTemplateId} required>
                        <option key='default' value={''}>
                            Select Email Template
                        </option>
                        {emailtemplates.map((option, index) => (
                            <option key={index} value={option.id} >
                                [ID-{props.uid}00{option.id}]  {option.title} 
                            </option>
                        ))}
                    </Form.Select>
                <Form.Control.Feedback type="invalid">Please select a template</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='p-2 d-flex justify-content-end'>
              <Button className='mx-1' variant="secondary" onClick={()=>setShowStartEmailCampaignModal(false)}>
                Cancel
              </Button>
              <Button type="submit" className={`mx-1 ${(fileName === '') ? 'disabled' : ''}`} variant="primary" >
                Ok
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showStartFormCampaignModal} centered>
						<Modal.Header>
							<div className="fs-6">Start Form Campaign for <b>{formsDetails.formcount}</b> forms</div>
						</Modal.Header>
						<Modal.Body className="p-2 shadow">
							<Form>
								<Form.Group className="mb-3">
									<Form.Label>Saved Templates</Form.Label>
									<Form.Select id="selectOptions" onChange={handleTemplateSelection}>
										<option key="default" value={-1}>
											Select Template to start a form campaign
										</option>
										{formtemplates.map((option, index) => (
											<option key={index} value={option.id}>
												{option.cname} [ID-{thisProps.uid}00{option.id}]
											</option>
										))}
									</Form.Select>
								</Form.Group>

								<div className="p-2 d-flex justify-content-between">
									<div>
										<div class="form-check form-switch">
											<input type="checkbox" id="modecheck" onChange={handleCampaignCheckbox} class="form-check-input" />

											<label title="" for="modecheck" class="form-check-label">
												Fast-Forward Mode
											</label>

											<OverlayTrigger key="tooltip-mode" placement={"right"} overlay={<Tooltip id={`tooltip-mode}`}>In fast-Forward mode, all automatable forms will be submitted. In default Manual Review mode, You can review which Forms you actually want to submit.</Tooltip>}>
												<i class="bi bi-question-circle-fill ps-1"></i>
											</OverlayTrigger>
										</div>
									</div>
									<div>
										<Button className="mx-1" title="Cancel" variant="secondary" onClick={() => setShowStartCampaign(false)}>
											Cancel
										</Button>

										<Button className={`mx-1`} title="Start a campaign with selected Template" variant="primary" onClick={startFormCampaign} disabled={[2, 3].includes(jobStatus) ? false : true}>
											{templatesLoading ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <>Start Campaign</>}
										</Button>
									</div>
								</div>
							</Form>
						</Modal.Body>
						<Modal.Footer style={{justifyContent: "flex-start"}}>
							<p className="mx-1">Note : You cannot run campaign for a paused list. Please wait for the List to get completed or Stop the crawl Job to run a campaign for this list</p>
						</Modal.Footer>
					</Modal>

      </>) : ''}
      {/*
      <Modal show={updatingJobsData || updatingLeadsData || updatingUCGData} backdrop="static" keyboard={false} className="d-flex justify-content-center align-items-center">
        <Modal.Body>
          <div className="fs-1 text-center"><i className="fa fa-spinner fa-pulse" /></div>
        </Modal.Body>
      </Modal>
      */}
    </React.Fragment >
  )
}

export default Main;
